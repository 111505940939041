import { LookupResponse } from "ipdata";

export enum SymptomsEnum {
  Nausea = 'nausea',
  Fatigue = 'fatigue',
  BraxtonHicks = 'braxtonHicks',
  BackPain = 'backPain',
  PelvicPain = 'pelvicPain',
  SkinChanges = 'skinChanges',
  NasalProblems = 'nasalProblems',
  SoreGums = 'soreGums',
  Dizziness = 'dizziness',
  LegCramps = 'legCramps',
  VaginalDischarge = 'vaginalDischarge',
  UrinaryTractInfection = 'urinaryTractInfection',
  ShortnessOfBreath = 'shortnessOfBreath',
  Heartburn = 'heartburn',
  SpiderVeins = 'spiderVeins',
  Hemorrhoids = 'hemorrhoids',
  FrequentUrination = 'frequentUrination',
  Leaking = 'leaking',
  Prolapse = 'prolapse',
  DiastasisRecti = 'diastasisRecti',
  HeadachesNeckPain = 'headachesNeckPain',
  WristPain = 'wristPain',
  Depression = 'depression',
  Constipation = 'constipation',
  ScarPain = 'scarPain',
  Exhaustion = 'exhaustion',
  PainfulSex = 'painfulSex',
  PerinealTear = 'perinealTear'
}

export enum LastBirthTimeEnum {
  LessThanFourWeeksAgo = 'lessThanFourWeeks',
  FiveToSixWeeks = 'fiveToSixWeeks',
  SevenToEightWeeks = 'sevenToEightWeeks',
  TwoToSixMonths = 'twoToSixMonths',
  SevenToTwelveMonths = 'sevenToTwelveMonths',
  MoreThanOneYear = 'moreThanOneYear',
}

export enum BirthTypeEnum {
  Vaginal = 'vaginal',
  CSection = 'c-section'
}

export enum ActivityLevelEnum {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Infrequently = 'infrequently'
}

export enum ActivitiesEnum {
  Walking = 'walking',
  Running = 'running',
  Cycling = 'cycling',
  Yoga = 'yoga',
  Pilates = 'pilates',
  Swimming = 'swimming',
  Dance = 'dance',
  WeightLifting = 'weightlifting',
  HIIT = 'HIIT'
}

export enum BirthPrepPlansEnum {
  BirthEducation = 'birthEducation',
  PrenatalFitness = 'prenatalFitness',
  PhysicalTherapy = 'physicalTherapy',
}

export enum CMInterestsEnum {
  PRBPPainFocus = 'prbpPainFocus',
  PRBPPrepFocus = 'prbpPrepFocus',
  Sessions = 'sessions',
  PostpartumRecoveryProgram = 'postpartumRecoveryProgram'
}

export enum CMContentEnum {
  PRBP = 'prbp',
  VBR = 'vbr',
  CSBR = 'csbr',
  RTE = 'rte',
}

export enum NoneLittleLot {
  None = 'none',
  Little = 'little',
  Lot = 'lot',
}

export enum NumberOfBirths {
  One = '1',
  Two = '2',
  Three = '3',
  FourPlus = '4 or more',
}

export enum Gestures {
  Wave = 'wave',
  Peace = 'peace',
  FistBump = 'fistbump',
  ThumbsUp = 'thumbsUp',
}

export const pregnancyPains = {
  [SymptomsEnum.BackPain]: {
    text: 'back pain'
  },
  [SymptomsEnum.HeadachesNeckPain]: {
    text: 'headaches and neck pain'
  },
  [SymptomsEnum.PelvicPain]: {
    text: 'pelvic pain'
  },
  [SymptomsEnum.LegCramps]: {
    text: 'leg cramps'
  },
  [SymptomsEnum.PainfulSex]: {
    text: 'pain during sex'
  },
}

export const firstTriSymptoms = {
  [SymptomsEnum.Nausea]: {
    text: 'nausea'
  },
  [SymptomsEnum.Fatigue]: {
    text: 'fatigue'
  }
}

export const secondTriSymptoms = {
  [SymptomsEnum.BraxtonHicks]: {
    text: 'braxton hicks'
  },
  [SymptomsEnum.BackPain]: {
    text: 'back pain'
  },
  [SymptomsEnum.PelvicPain]: {
    text: 'pelvic pain'
  },
  [SymptomsEnum.SkinChanges]: {
    text: 'skin changes'
  },
  [SymptomsEnum.NasalProblems]: {
    text: 'nasal problems'
  },
  [SymptomsEnum.SoreGums]: {
    text: 'sore gums'
  },
  [SymptomsEnum.Dizziness]: {
    text: 'dizziness'
  },
  [SymptomsEnum.LegCramps]: {
    text: 'leg cramps'
  },
  [SymptomsEnum.VaginalDischarge]: {
    text: 'vaginal discharge'
  },
  [SymptomsEnum.UrinaryTractInfection]: {
    text: 'urinary tract infection'
  },
}

export const thirdTriSymptoms = {
  [SymptomsEnum.BraxtonHicks]: {
    text: 'braxton hicks'
  },
  [SymptomsEnum.BackPain]: {
    text: 'back pain'
  },
  [SymptomsEnum.PelvicPain]: {
    text: 'pelvic pain'
  },
  [SymptomsEnum.ShortnessOfBreath]: {
    text: 'shortness of breath'
  },
  [SymptomsEnum.Heartburn]: {
    text: 'heartburn'
  },
  [SymptomsEnum.SpiderVeins]: {
    text: 'spider veins'
  },
  [SymptomsEnum.Hemorrhoids]: {
    text: 'hemorrhoids'
  },
  [SymptomsEnum.FrequentUrination]: {
    text: 'frequent urination'
  },
}

export const postpartumSymptoms = {
  [SymptomsEnum.Leaking]: {
    text: 'leaking'
  },
  [SymptomsEnum.Prolapse]: {
    text: 'prolapse'
  },
  [SymptomsEnum.DiastasisRecti]: {
    text: 'diastasis recti'
  },
  [SymptomsEnum.BackPain]: {
    text: 'back pain'
  },
  [SymptomsEnum.HeadachesNeckPain]: {
    text: 'headache/neck pain'
  },
  [SymptomsEnum.WristPain]: {
    text: 'wrist pain'
  },
  [SymptomsEnum.PelvicPain]: {
    text: 'pelvic pain'
  },
  [SymptomsEnum.Depression]: {
    text: 'depression'
  },
  [SymptomsEnum.Constipation]: {
    text: 'constipation'
  },
  [SymptomsEnum.ScarPain]: {
    text: 'scar pain'
  },
  [SymptomsEnum.Exhaustion]: {
    text: 'exhaustion'
  },
  [SymptomsEnum.PainfulSex]: {
    text: 'pain with sex'
  },
}

export const lastBirthTime = {
  [LastBirthTimeEnum.LessThanFourWeeksAgo]: {
    text: 'less than 4 weeks ago'
  },
  [LastBirthTimeEnum.FiveToSixWeeks]: {
    text: '5-6 weeks ago'
  },
  [LastBirthTimeEnum.SevenToEightWeeks]: {
    text: '7-8 weeks ago'
  },
  [LastBirthTimeEnum.TwoToSixMonths]: {
    text: '2-6 months ago'
  },
  [LastBirthTimeEnum.SevenToTwelveMonths]: {
    text: '7-12 months ago'
  },
  [LastBirthTimeEnum.MoreThanOneYear]: {
    text: 'more than a year ago'
  },
}

export const deliveryType = {
  [BirthTypeEnum.Vaginal]: {
    text: 'vaginal'
  },
  [BirthTypeEnum.CSection]: {
    text: 'c-section'
  },
}

export const activityLevel = {
  [ActivityLevelEnum.Daily]: {
    text: 'daily'
  },
  [ActivityLevelEnum.Weekly]: {
    text: 'a few times a week'
  },
  [ActivityLevelEnum.Monthly]: {
    text: 'a few times a month'
  },
  [ActivityLevelEnum.Infrequently]: {
    text: 'infrequently'
  },
}

export const activities = {
  [ActivitiesEnum.Walking]: {
    text: 'walking'
  },
  [ActivitiesEnum.Running]: {
    text: 'running'
  },
  [ActivitiesEnum.Cycling]: {
    text: 'cycling'
  },
  [ActivitiesEnum.Yoga]: {
    text: 'yoga'
  },
  [ActivitiesEnum.Pilates]: {
    text: 'pilates'
  },
  [ActivitiesEnum.Swimming]: {
    text: 'swimming'
  },
  [ActivitiesEnum.Dance]: {
    text: 'dance'
  },
  [ActivitiesEnum.WeightLifting]: {
    text: 'weightlifting'
  },
  [ActivitiesEnum.HIIT]: {
    text: 'HIIT'
  },
}

export const birthPrepPlans = {
  [BirthPrepPlansEnum.BirthEducation]: {
    text: 'birth education'
  },
  [BirthPrepPlansEnum.PrenatalFitness]: {
    text: 'prenatal fitness'
  },
  [BirthPrepPlansEnum.PhysicalTherapy]: {
    text: 'physical therapy'
  },
}

export const thoughtBirthRecovery = {
  [NoneLittleLot.None]: {
    text: 'none yet'
  },
  [NoneLittleLot.Little]: {
    text: 'a little bit'
  },
  [NoneLittleLot.Lot]: {
    text: 'a lot'
  },
}


export const confidenceLevel = {
  [NoneLittleLot.None]: {
    text: 'not confident'
  },
  [NoneLittleLot.Little]: {
    text: 'somewhat confident'
  },
  [NoneLittleLot.Lot]: {
    text: 'very confident'
  },
}

export const numberOfBirths = {
  [NumberOfBirths.One]: {
    text: 'one',
    textOrdinal: 'first',
  },
  [NumberOfBirths.Two]: {
    text: 'two',
    textOrdinal: 'second',
  },
  [NumberOfBirths.Three]: {
    text: 'three',
    textOrdinal: 'third',
  },
  [NumberOfBirths.FourPlus]: {
    text: 'four or more',
    textOrdinal: 'fourth or more',
  },
}

export const gestures = {
  [Gestures.Wave]: {
    text: '👋 wave',
  },
  [Gestures.Peace]: {
    text: '✌ peace',
  },
  [Gestures.FistBump]: {
    text: '🤛 fist bump',
  },
  [Gestures.ThumbsUp]: {
    text: '👍 thumbs up',
  }
}

export type CurrentPregnancy = {
  isCurrentlyPregnant: boolean;
  dueDate?: string;
  trimester?: 1 | 2 | 3;
  weeks?: number;
  symptoms?: Array<keyof typeof firstTriSymptoms | keyof typeof secondTriSymptoms | keyof typeof thirdTriSymptoms>,
  plannedDeliveryType?: keyof typeof deliveryType;
  birthPrepPlans?: Array<keyof typeof birthPrepPlans>;
  birthRecoveryThoughtLevel?: keyof typeof thoughtBirthRecovery;
  postpartumConcerns?: Array<keyof typeof postpartumSymptoms>;
  birthNumber?: keyof typeof numberOfBirths;
}

export type LastBirth = {
  hasGivenBirthBefore: boolean;
  deliveryType?: keyof typeof deliveryType;
  birthNumber?: keyof typeof numberOfBirths;
  date?: string;
}

type SurveyResponse = {
  id?: string;
  email?: string;
  name?: string;
  currentPregnancy?: CurrentPregnancy;
  lastBirth?: LastBirth;
  postpartumSymptoms?: Array<keyof typeof postpartumSymptoms>;
  beenToPhysicalTherapy?: boolean;
  interestedIn?: Array<CMInterestsEnum>;
  ipData?: LookupResponse;
  physActivityLevel?: keyof typeof activityLevel;
  physActivities?: Array<keyof typeof activities>;
  confidenceLevel?: (keyof typeof confidenceLevel);
  coachingHelpful?: boolean;
  favoriteGesture?: (keyof typeof gestures);
  seenConvincers?: Array<string>;
  suggestedContent?: Array<CMContentEnum>;
}

export default SurveyResponse;

export {};