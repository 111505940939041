import React from "react";
import moment from "moment";
import Box from "components/Box";
import ImgDownArrow from "assets/images/down-arrow.svg";
import FadeIn from "components/FadeIn";
import styled from "styled-components";
import SurveyResponse, {
  activities,
  BirthTypeEnum,
  postpartumSymptoms,
  pregnancyPains,
} from "./survey-response";
import { getPains } from "./util";
import { upperCase } from "lodash";
import Button from "survey/components/Button";

const Goal = styled(Box)`
  font-size: ${(p) => p.theme.fontSizes[3]}px;
  color: ${(p) => p.theme.colors.black90};
  margin-bottom: ${(p) => p.theme.space[3]}px;
  padding-left: 32px;
  position: relative;
  line-height: 1.4;
  &::before {
    font-size: 26px;
    line-height: 0.2em;
    content: "✓";
    color: ${(p) => p.theme.colors.primary};
    width: 16px;
    height: 16px;
    border: 1px solid ${(p) => p.theme.colors.primary};
    position: absolute;
    top: 4px;
    left: 0;
  }
`;

const Eyebrow = styled(Box)`
  font-size: ${(p) => p.theme.fontSizes[1]}px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.primary};
  position: absolute;
  top: -24px;
`;

const EyebrowFilled = styled(Eyebrow)`
  background-color: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  padding: 1px 8px;
  top: -30px;
`;

const PointLarge = styled(Box)`
  width: 13px;
  height: 13px;
  background-color: ${(p) => p.theme.colors.primary};
  border-radius: 99999px;
  position: absolute;
  left: 0;
  top: 10px;
`;

const PointSmall = styled(Box)`
  width: 7px;
  height: 7px;
  background-color: ${(p) => p.theme.colors.lightYellow};
  border: 1px solid ${(p) => p.theme.colors.primary};
  border-radius: 99999px;
  position: absolute;
  left: 3px;
  top: 10px;
`;

const TimelineItemLarge = styled(Box)<{ isLast: boolean }>`
  text-align: left;
  position: relative;
  padding-left: 32px;
  margin-bottom: 32px;
  margin-top: 64px;
  h3 {
    font-size: ${(p) => p.theme.fontSizes[4]}px;
    color: ${(p) => p.theme.colors.black90};
    line-height: ${(p) => p.theme.lineHeights.heading};
  }
  p {
    font-size: ${(p) => p.theme.fontSizes[3]}px;
    color: ${(p) => p.theme.colors.black60};
    margin-bottom: 0;
  }
  &::before {
    content: "";
    position: absolute;
    left: 6px;
    top: 16px;
    height: ${(p) => (p.isLast ? "100%" : "calc(100% + 64px)")};
    width: 0px;
    border-right: 1px ${(p) => (p.isLast ? "dashed" : "solid")} ${(p) => p.theme.colors.primary};
  }
  &::after {
    @keyframes nudge {
      0% {
        transform: translateY(0);
      }
      16% {
        transform: translateY(8px);
      }
      32% {
        transform: translateY(0);
      }
      48% {
        transform: translateY(8px);
      }
      64% {
        transform: translateY(0);
      }
      78% {
        transform: translateY(8px);
      }
      94% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(0);
      }
    }
    display: ${(p) => (p.isLast ? "block" : "none")};
    content: "";
    background-image: url(${ImgDownArrow});
    background-repeat: none;
    background-size: 18px;
    position: absolute;
    left: -3px;
    bottom: -24px;
    width: 18px;
    height: 9px;
    animation: nudge 3s ease-in-out infinite;
  }
`;

const TimelineItemSmall = styled(Box)<{
  isLast?: boolean;
  highlight?: boolean;
}>`
  text-align: left;
  position: relative;
  padding-left: 32px;
  margin-bottom: 24px;
  margin-top: 16px;
  h3 {
    font-size: ${(p) => p.theme.fontSizes[2]}px;
    color: ${(p) => p.theme.colors.primary};
  }
  &::before {
    display: ${(p) => (p.isLast ? "none" : "block")};
    content: "";
    position: absolute;
    left: 6px;
    top: 16px;
    height: calc(100% + 64px);
    width: 1px;
    background-color: ${(p) => p.theme.colors.primary};
  }
`;

type PlanItem = {
  size: "small" | "large";
  eyebrow?: {
    text: string;
    filled: boolean;
  };
  title?: string;
  text?: string;
};

const content = {
  prbp: {
    title: "Pain Relief & Birth Prep Class",
    text: "A 2-hour streaming class taught by expert pelvic floor therapists. Designed to reduce pregnancy pain and prepare your body for birth.",
  },
  vbrPregnant: {
    title: "Vaginal Birth Recovery Program",
    text: "Start healing as soon as you’re ready. Stream our vaginal birth recovery program from home. 3 classes a week for 12 weeks.",
    weeks: 12,
    sections: [
      {
        name: "rehab",
        lengthWeeks: 4,
      },
      {
        name: "strength",
        lengthWeeks: 4,
      },
      {
        name: "power",
        lengthWeeks: 4,
      },
    ],
  },
  vbrPostpartum: {
    title: "Vaginal Birth Recovery Program",
    text: "Start streaming our vaginal birth recovery program today. 3 classes a week for 12 weeks.",
    weeks: 12,
    sections: [
      {
        name: "rehab",
        lengthWeeks: 4,
      },
      {
        name: "strength",
        lengthWeeks: 4,
      },
      {
        name: "power",
        lengthWeeks: 4,
      },
    ],
  },
  csbrPregnant: {
    title: "C-Section Recovery Program",
    text: "Start healing as soon as you’re ready. Stream our c-section recovery program from home. 3 classes a week for 14 weeks.",
    weeks: 14,
    sections: [
      {
        name: "rehab",
        lengthWeeks: 6,
      },
      {
        name: "strength",
        lengthWeeks: 4,
      },
      {
        name: "power",
        lengthWeeks: 4,
      },
    ],
  },
  csbrPostpartum: {
    title: "C-Section Recovery Program",
    text: "Start streaming our c-section recovery program today. 3 classes a week for 14 weeks.",
    weeks: 14,
    sections: [
      {
        name: "rehab",
        lengthWeeks: 6,
      },
      {
        name: "strength",
        lengthWeeks: 4,
      },
      {
        name: "power",
        lengthWeeks: 4,
      },
    ],
  },
  rte: {
    title: "Return to Exercise Program",
    text: "Start streaming our Return to Exercise program today. 3 classes a week for 11 weeks.",
    weeks: 11,
    sections: [
      {
        name: "rehab",
        lengthWeeks: 3,
      },
      {
        name: "strength",
        lengthWeeks: 4,
      },
      {
        name: "power",
        lengthWeeks: 4,
      },
    ],
  },
};

const getString = (parts: string[]) => {
  let s = "";
  parts.forEach((part, index) => {
    if (index === 0) {
      s = `${part}`;
    } else if (index !== parts.length - 1) {
      s = `${s}, ${part}`;
    } else {
      s = `${s} and ${part}`;
    }
  });
  return s;
};

export enum PaymentPlans {
  Monthly = "monthly",
  Quarterly = "quarterly",
  Annually = "annually",
}

export const paymentPlans = {
  [PaymentPlans.Monthly]: {
    name: "1 Month Membership",
    price: "25",
    slashPrice: null,
    period: "mo",
    durationInMonths: 1,
    url: 'https://watch.chelseamethod.com/orders/customer_info?o=24143',
    description:
      "Based on your plan, we recommend purchasing monthly membership.",
  },
  [PaymentPlans.Quarterly]: {
    name: "3 Month Membership",
    price: "60",
    slashPrice: "75",
    period: "3 mo",
    durationInMonths: 3,
    url: '',
    description:
      "Based on your plan, we recommend purchasing a 3 month membership. You’ll save 20% (vs. monthly).",
  },
  [PaymentPlans.Annually]: {
    name: "1 Year Membership",
    slashPrice: "300",
    price: "150",
    period: "yr",
    durationInMonths: 12,
    url: '',
    description:
      "Based on your plan, we recommend purchasing a 1 year membership. You’ll save 50% (vs. monthly).",
  },
};

const Plan = (props: { onSubmit: (plan: PaymentPlans) => any, surveyResponse: SurveyResponse }) => {
  const { onSubmit, surveyResponse: sr } = props;
  const planObj: PlanItem[] = [];
  const goalsObj: string[] = [];
  let totalWeeks: number = 52;
  let recoPaymentPlan: PaymentPlans = PaymentPlans.Monthly;
  

  let ppRecoveryProgram;
  if (sr.currentPregnancy?.isCurrentlyPregnant) {
    if (sr.currentPregnancy.plannedDeliveryType === BirthTypeEnum.Vaginal) {
      ppRecoveryProgram = content.vbrPregnant;
    } else if (
      sr.currentPregnancy.plannedDeliveryType === BirthTypeEnum.CSection
    ) {
      ppRecoveryProgram = content.csbrPregnant;
    }
  } else if (sr.lastBirth?.hasGivenBirthBefore) {
    if (sr.lastBirth.deliveryType === BirthTypeEnum.Vaginal) {
      if (moment().diff(moment(sr.lastBirth.date), "weeks") < 6) {
        ppRecoveryProgram = content.vbrPostpartum;
      } else {
        ppRecoveryProgram = content.rte;
      }
    } else if (sr.lastBirth.deliveryType === BirthTypeEnum.CSection) {
      if (moment().diff(moment(sr.lastBirth.date), "weeks") < 8) {
        ppRecoveryProgram = content.csbrPostpartum;
      } else {
        ppRecoveryProgram = content.rte;
      }
    }
  }

  if (sr.currentPregnancy?.isCurrentlyPregnant) {
    const pains = getPains(sr);
    const hasPain = pains.length > 0;
    if (hasPain) {
      goalsObj.push(
        `Relieve your pregnancy-related ${getString(
          pains.map((pain) => pregnancyPains[pain].text)
        )}`
      );
    }
    switch (sr.currentPregnancy?.trimester) {
      case 1: {
        break;
      }
      case 2: {
        goalsObj.push(`Prepare your body for your birth`);
        planObj.push({
          size: "large",
          eyebrow: {
            text: `START TODAY — ${moment().format("MMM DD, YYYY")}`,
            filled: true,
          },
          title: content.prbp.title,
          text: content.prbp.text,
        });
        planObj.push({
          size: "small",
          title:
            "Session with a Chelsea Method Physical Therapist",
        });
        break;
      }
      case 3: {
        goalsObj.push(`Prepare your body for your birth`);
        planObj.push({
          size: "large",
          eyebrow: {
            text: `START TODAY — ${moment().format("MMM DD, YYYY")}`,
            filled: true,
          },
          title: content.prbp.title,
          text: content.prbp.text,
        });
        planObj.push({
          size: "small",
          title:
            "Session with a Chelsea Method Physical Therapist",
        });
        break;
      }
      default: {
      }
    }

    goalsObj.push(`Recover from birth safely and confidently`);
    sr.physActivities &&
      goalsObj.push(
        `Return safely to ${getString(
          sr.physActivities?.map((act) => activities[act].text)
        )}`
      );

    planObj.push({
      size: "large",
      eyebrow: {
        text: moment(sr.currentPregnancy?.dueDate).format("MMM DD, YYYY"),
        filled: false,
      },
      title: "Your due date 👶",
    });

    planObj.push({
      size: "large",
      title: "Start " + ppRecoveryProgram?.title || "Birth Recovery Program",
      text:
        ppRecoveryProgram?.text ||
        "Stream one of our birth recovery programs from home. 3 classes a week.",
    });

    planObj.push({
      size: "small",
      title:
        "Session with a Chelsea Method Physical Therapist",
    });

    ppRecoveryProgram?.sections.forEach((section) => {
      planObj.push({
        size: "small",
        title: `${upperCase(section.name)} — ${section.lengthWeeks} WEEKS`,
      });
    });

    planObj.push({
      size: "large",
      title: `Complete ${ppRecoveryProgram?.title}`,
    });

    totalWeeks = moment(sr.currentPregnancy?.dueDate)
      .add(ppRecoveryProgram?.weeks, "weeks")
      .diff(moment(), "weeks");
  } else if (sr.lastBirth?.hasGivenBirthBefore) {
    if (sr.lastBirth.deliveryType === BirthTypeEnum.CSection) {
      goalsObj.push(`Recover from your c-section`);
    } else if (sr.lastBirth.deliveryType === BirthTypeEnum.Vaginal) {
      goalsObj.push(`Recover from your vaginal birth`);
    }
    if (sr.postpartumSymptoms && sr.postpartumSymptoms.length > 0) {
      goalsObj.push(
        `Treat your ${getString(
          sr.postpartumSymptoms.map(
            (symptom) => postpartumSymptoms[symptom].text
          )
        )}`
      );
    }
    if (sr.physActivities && sr.physActivities.length > 0) {
      goalsObj.push(
        `Build strength and power for ${getString(
          sr.physActivities.map((activity) => activities[activity].text)
        )}`
      );
    }
    planObj.push({
      size: "large",
      eyebrow: {
        text: moment(sr.lastBirth?.date).format("MMM DD, YYYY"),
        filled: false,
      },
      title: "Your last birth",
    });
    planObj.push({
      size: "large",
      eyebrow: {
        text: `START TODAY — ${moment().format("MMM DD, YYYY")}`,
        filled: true,
      },
      title: "Start " + ppRecoveryProgram?.title || "Birth Recovery Program",
      text:
        ppRecoveryProgram?.text ||
        "Stream one of our birth recovery programs from home. 3 classes a week.",
    });

    planObj.push({
      size: "small",
      title:
        "Session with a Chelsea Method Physical Therapist",
    });

    ppRecoveryProgram?.sections.forEach((section) => {
      planObj.push({
        size: "small",
        title: `${upperCase(section.name)} — ${section.lengthWeeks} WEEKS`,
      });
    });

    planObj.push({
      size: "large",
      title: `Complete ${ppRecoveryProgram?.title}`,
    });

    totalWeeks = moment()
      .add(ppRecoveryProgram?.weeks, "weeks")
      .diff(moment(), "weeks");
  }

  if (totalWeeks <= 8) {
    recoPaymentPlan = PaymentPlans.Monthly;
  } else if (totalWeeks <= 24) {
    recoPaymentPlan = PaymentPlans.Quarterly;
  } else {
    recoPaymentPlan = PaymentPlans.Annually;
  }

  const renderTimelineItem = (item: PlanItem, isLast: boolean) => {
    switch (item.size) {
      case "small": {
        return (
          <TimelineItemSmall isLast={isLast}>
            <PointSmall />
            <h3>{item.title}</h3>
          </TimelineItemSmall>
        );
      }
      case "large": {
        return (
          <TimelineItemLarge isLast={isLast}>
            <PointLarge />
            {item.eyebrow && item.eyebrow.filled && (
              <EyebrowFilled>{item.eyebrow.text}</EyebrowFilled>
            )}
            {item.eyebrow && !item.eyebrow.filled && (
              <Eyebrow>{item.eyebrow.text}</Eyebrow>
            )}
            <h3>{item.title}</h3>
            {item.text && <p>{item.text}</p>}
          </TimelineItemLarge>
        );
      }
    }
  };

  const renderRecoPaymentOption = (plan: PaymentPlans) => {
    return (
      <Box
        backgroundColor="white"
        padding={4}
        borderRadius="3px"
        boxShadow="rgba(0,0,0,0.05) 0 2px 8px"
        mb={5}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Box>
            <Box fontSize={3} fontWeight={700} color="black90" mb={3}>
              {paymentPlans[plan].name}
            </Box>
          </Box>
          <Box
            pr={1}
            letterSpacing="-0.06rem"
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            position="relative"
            top="-4px"
          >
            {paymentPlans[plan].slashPrice && (
              <Box fontSize={4} color="black50" mr={2} position="relative">
                ${paymentPlans[plan].slashPrice}
                <Box
                  position="absolute"
                  style={{ transform: "rotate(15deg)" }}
                  height="1px"
                  width="100%"
                  backgroundColor="red"
                  top="50%"
                  left="0px"
                />
              </Box>
            )}
            <Box style={{ whiteSpace: 'pre' }}>
              <Box as="span" fontSize={4} color="black90" fontWeight={700}>
                ${paymentPlans[plan].price}
              </Box>
              <Box as="span" fontSize={2} color="black60">
                /{paymentPlans[plan].period}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box fontSize={2} color="black60" mb={5}>
          {paymentPlans[plan].description}
        </Box>
        <Button buttonType="filled" onClick={() => onSubmit(plan)}>continue</Button>
      </Box>
    );
  };

  const renderOtherPaymentOption = (plan: PaymentPlans) => {
    return (
      <Box
        borderTop="1px solid"
        borderColor="border"
        pt={3}
        pb={3}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box fontSize={2} fontWeight={700} color="black90">
          {paymentPlans[plan].name}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box mr={3}>
            <Box as="span" fontSize={2} color="black90" fontWeight={700}>
              ${paymentPlans[plan].price}
            </Box>
            <Box as="span" fontSize={2} color="black60">
              /{paymentPlans[plan].period}
            </Box>
          </Box>
          <Button buttonHeight="short" onClick={() => onSubmit(plan)}>select</Button>
        </Box>
      </Box>
    );
  };

  const renderPaymentInfo = (recoPlan: PaymentPlans) => {
    switch (recoPlan) {
      case PaymentPlans.Monthly: {
        return (
          <Box>
            {renderRecoPaymentOption(recoPlan)}
            <Box>
              <Box
                mb={3}
                fontWeight="600"
                fontSize={1}
                color="primary"
                letterSpacing="0.02rem"
              >
                OTHER MEMBERSHIP OPTIONS
              </Box>
              {renderOtherPaymentOption(PaymentPlans.Quarterly)}
              {renderOtherPaymentOption(PaymentPlans.Annually)}
            </Box>
          </Box>
        );
      }
      case PaymentPlans.Quarterly: {
        return (
          <Box>
            {renderRecoPaymentOption(recoPlan)}
            <Box>
              <Box
                mb={3}
                fontWeight="600"
                fontSize={1}
                color="primary"
                letterSpacing="0.02rem"
              >
                OTHER MEMBERSHIP OPTIONS
              </Box>
              {renderOtherPaymentOption(PaymentPlans.Monthly)}
              {renderOtherPaymentOption(PaymentPlans.Annually)}
            </Box>
          </Box>
        );
      }
      case PaymentPlans.Annually: {
        return (
          <Box>
            {renderRecoPaymentOption(recoPlan)}
            <Box>
              <Box
                mb={3}
                fontWeight="600"
                fontSize={1}
                color="primary"
                letterSpacing="0.02rem"
              >
                OTHER MEMBERSHIP OPTIONS
              </Box>
              {renderOtherPaymentOption(PaymentPlans.Monthly)}
              {renderOtherPaymentOption(PaymentPlans.Quarterly)}
            </Box>
          </Box>
        );
      }
    }
  };

  return (
    <Box>
      <FadeIn delay={500}>
        <Box
          bg="white"
          maxWidth="480px"
          m={["0 8px 64px 8px", "0 auto 64px auto"]}
          p={[5]}
          textAlign="left"
          boxShadow="rgba(0,0,0,0.05) 0 2px 8px"
          borderRadius="3px"
        >
          <Box
            mb={3}
            fontWeight="600"
            fontSize={1}
            color="primary"
            letterSpacing="0.02rem"
          >
            YOUR SUCCESS IS OUR SUCCESS
          </Box>
          <Box mb={3} fontSize={3} color="black90">
            We’ll work together to:
          </Box>
          {goalsObj.map((goal, i) => (
            <FadeIn delay={750 + i * 300} key={goal}>
              <Goal>{goal}</Goal>
            </FadeIn>
          ))}
        </Box>
      </FadeIn>
      <Box width="100%" maxWidth="420px" m="0 auto" p={["0 16px", "0"]}>
        {planObj.map((item, index) => {
          return (
            <FadeIn
              key={item.title}
              delay={750 + goalsObj.length * 300 + 500 + 500 * index}
            >
              {renderTimelineItem(item, index === planObj.length - 1)}
            </FadeIn>
          );
        })}
        <Box mt={6} textAlign="left">
          <Box fontSize={4} fontWeight={700} mb={2} color="black90">
            The motivation you need to achieve your goals
          </Box>
          {renderPaymentInfo(recoPaymentPlan)}
        </Box>
      </Box>
    </Box>
  );
};

export default Plan;
