import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse, { deliveryType } from 'survey/v1/survey-response';

const PlannedDeliveryType = (props: { onSubmit: (id: keyof typeof deliveryType) => any, defaultResponse?: SurveyResponse }) => {

  const { onSubmit, defaultResponse } = props;

  let defaultSelection;
  if (defaultResponse?.currentPregnancy?.birthNumber !== undefined) {
    defaultSelection = [defaultResponse?.currentPregnancy?.birthNumber];
  }

  return <Box m="0 auto">
    <MultipleChoice
      question="Are you planning on having a vaginal or c-section delivery?"
      onChange={(selected) => onSubmit(selected[0] as (keyof typeof deliveryType))}
      choices={Object.entries(deliveryType).map(s => ({ id: s[0], text: s[1].text }))}
      defaultSelection={defaultSelection}
    />
  </Box> 
}

export default PlannedDeliveryType;
