import Box from "components/Box"
import { useState } from "react";
import Button from "survey/components/Button";
import { FormWrap } from "survey/components/Form";
import TextInput from "survey/components/TextInput";
import SurveyResponse from "./survey-response";

const Email = (props: { onSubmit: (email?: string, name?: string) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit } = props;
  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();

  const handleEmailChange = (val: string) => {
    setEmail(val);
  }

  const handleNameChange = (val: string) => {
    setName(val);
  }

  return (
    <FormWrap>
      <Box fontSize={3} mb={4}>
        <b>Before we build your plan, let’s save your answers.</b>
      </Box>
      <Box mb={4}>
        <TextInput
          question="What’s your email address?"
          onSubmit={onSubmit}
          placeholder="spicy.mama42@gmail.com"
          autocomplete="email"
          hideButton
          onChange={handleEmailChange}
        />
      </Box>
      <Box mb={4}>
        <TextInput
          question="What do you like to be called?"
          onSubmit={onSubmit}
          placeholder="Megan"
          autocomplete="given-name"
          hideButton
          onChange={handleNameChange}
        />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Button onClick={() => onSubmit(email, name)} buttonType="submit" disabled={!name || !email}>
          View plan
        </Button>
      </Box>
    </FormWrap>
  ); 
}

export default Email;
