import { Input } from "antd"
import { ChangeEvent, ReactNode, useState } from "react";
import Box from "components/Box"
import { FormWrap } from 'survey/components/Form';
import Button from 'survey/components/Button';
import styled from "styled-components";

type TextInputProps = {
  onSubmit?: (value?: string) => any;
  onChange?: (value: string) => any;
  question: ReactNode;
  placeholder?: string;
  autocomplete?: string;
  buttonLabel?: string;
  hideButton?: boolean;
}

const SInput = styled(Input)`
  height: 50px;
  width: 300px;
  border-radius: 0;
  font-size: ${props => props.theme.fontSizes[3]}px;
  margin: 0 auto;
`;

const TextInput = (props: TextInputProps) => {
  const {
    question,
    onChange,
    onSubmit,
    placeholder,
    autocomplete,
    buttonLabel,
    hideButton,
  } = props;

  const [value, setValue] = useState<string>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget?.value);
    onChange && onChange(e.currentTarget?.value);
  }

  return (
    <Box>
      <Box fontSize={3} mb={3}>
        {question}
      </Box>
      <FormWrap>
        <SInput
          onChange={handleChange}
          placeholder={placeholder}
          autoComplete={autocomplete}
        />
        {!hideButton && <Box width="300px" ml="auto" mr="auto" mt={3}>
          <Button buttonType="filled" onClick={() => onSubmit && onSubmit(value)}>{buttonLabel || 'next'}</Button>
        </Box>}
      </FormWrap>
    </Box>
  );
}

export default TextInput;
