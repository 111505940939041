import Box from "components/Box";
import Button from "survey/components/Button";
import { FormWrap } from "survey/components/Form";
import SurveyResponse from "./survey-response";

const PRBPPainInfo2 = (props: { onSubmit: () => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  let trimesterText = '.';
  if (defaultResponse?.currentPregnancy?.trimester === 2) {
    trimesterText = ' in your second trimester.';
  } else if (defaultResponse?.currentPregnancy?.trimester === 3) {
    trimesterText = ' in your third trimester.';
  }

  return (
    <FormWrap fontSize={3} mb={3} color="black" m="0 auto" display="flex" alignItems="center" flexDirection="column">
      <Box mb={3}>
        <p>
          {`This 2-hour streaming class uses proven principles from physical therapy to target common pregnancy-related issues${trimesterText}`}
        </p>
        <p>
          This includes hip, groin, pelvic, pubic bone, back, vaginal, rectal, rib, shoulder, abdominal and neck pain.
        </p>
        <p>
          You can do it all at once or split it up over several days.
        </p>
      </Box>
      <Button buttonType="submit" onClick={onSubmit}>NEXT</Button>
    </FormWrap>
  ); 
}

export default PRBPPainInfo2;