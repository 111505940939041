
import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse from "./survey-response";

const PRBPPrepInfo1 = (props: { onSubmit: (response: boolean) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit } = props;

  const content = <div>
    <p>Evidence shows that a narrow pelvic outlet increases the risk of emergency c-section. The good news: it’s possible for your pelvis to open by up to 30%.</p>
    <p>Our <b>Pain Relief & Birth Prep</b> class will show you how to loosen restricted areas that could prevent your pelvis from opening to it’s full potential during birth.</p>
    <p>Are you interested in learning more about how to prepare your body for birth?</p>
  </div>

  return <Box>
    <MultipleChoice
      question={
        <Box>
          {content}
        </Box>
      }
      onChange={(selected) => {
        const response = selected[0] === 'yes' ? true : false;
        onSubmit(response)
      }}
      choices={[
        { id: 'yes', text: `i’m interested` },
        { id: 'no', text: 'not now' },
      ]}
    />
  </Box> 
}

export default PRBPPrepInfo1;