import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBqgAp6BBJ6R3K5dfU3D8AmW8V18bSj2dQ",
  authDomain: "chelsea-4c143.firebaseapp.com",
  projectId: "chelsea-4c143",
  storageBucket: "chelsea-4c143.appspot.com",
  messagingSenderId: "84904699445",
  appId: "1:84904699445:web:9d561c02f696157fa6337c",
  measurementId: "G-YN8JS2DTZ0"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

var db = firebaseApp.firestore();
if (window.location.hostname === "localhost") {
  db.useEmulator("localhost", 8080);
}

const fs = { db };

export default fs;