
import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse, { SymptomsEnum } from "./survey-response";
import { getPains } from "./util";

const PRBPPainInfo1 = (props: { onSubmit: (response: boolean) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  const pains = getPains(defaultResponse);
  const backOrPelvicPain = pains.includes(SymptomsEnum.BackPain) || pains.includes(SymptomsEnum.PelvicPain);

  let content;
  if (backOrPelvicPain) {
    content = <div>
      <p>Studies show that therapeutic exercise—like you’ll learn with Chelsea Method—can help decrease back and pelvic pain during and after pregnancy.</p>
      <p>Our 2-hour <b>Pain Relief & Birth Prep</b> class targets pregnancy-related back and pelvic issues like yours. You can start streaming it today.</p>
      <p>Would you like to learn a little more about how our <b>Pain Relief & Birth Prep</b> class treats pregnancy-related issues?</p>
    </div>;
  } else {
    content = <div>
      <p>Our 2-hour <b>Pain Relief & Birth Prep</b> class targets pregnancy-related issues like yours. You can start streaming it today.</p>
      <p>Would you like to learn a little more about how our <b>Pain Relief & Birth Prep</b> class treats pregnancy-related issues?</p>
    </div>;
  }

  return <Box>
    <MultipleChoice
      question={
        <Box>
          {content}
        </Box>
      }
      onChange={(selected) => {
        const response = selected[0] === 'yes' ? true : false;
        onSubmit(response)
      }}
      choices={[
        { id: 'yes', text: `i’m interested` },
        { id: 'no', text: 'not now' },
      ]}
    />
  </Box> 
}

export default PRBPPainInfo1;