import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse from "./survey-response";

const BirthBefore = (props: { onSubmit: (id: string) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  let defaultSelection;
  if (defaultResponse?.lastBirth?.hasGivenBirthBefore !== undefined) {
    defaultSelection = defaultResponse.lastBirth.hasGivenBirthBefore ? ['yes'] : ['no'];
  }

  return <Box>
    <MultipleChoice
      question="Have you given birth before?"
      onChange={(selected) => onSubmit(selected[0])}
      choices={[
        { id: 'yes', text: 'yes' },
        { id: 'no', text: 'no' },
      ]}
      defaultSelection={defaultSelection}
    />
  </Box> 
}

export default BirthBefore;