import styled from "styled-components";
import deepmerge from "deepmerge";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import IPData from "ipdata";
import firebaseApp from "firebase/firebaseApp";
import { useEffect, useState } from "react";
import moment from "moment";
import Box from "components/Box";
import SurveyResponse, { ActivitiesEnum, ActivityLevelEnum, BirthTypeEnum, CMInterestsEnum, confidenceLevel, gestures, postpartumSymptoms } from "survey/v1/survey-response";
import { ReactComponent as Logo } from "assets/logo-purple.svg";
import CurrentlyPregnant from "survey/v1/CurrentlyPregnant";
import DueDate from "survey/v1/DueDate";
import LastBirth from "survey/v1/LastBirth";
import BirthBefore from "survey/v1/BirthBefore";
import BirthTypeComponent from "survey/v1/BirthType";
import FirstTriIssues from "survey/v1/FirstTriIssues";
import SecondTriIssues from "survey/v1/SecondTriIssues";
import ThirdTriIssues from "survey/v1/ThirdTriIssues";
import PhysActivities from "survey/v1/PhysActivities";
import PhysTherapyBefore from "survey/v1/PhysTherapyBefore";
import PhysActivityLevel from "survey/v1/PhysActivityLevel";
import PRBPPainInfo1 from "survey/v1/PRBPPainInfo1";
import PlannedDeliveryType from "./PlannedDeliveryType";
import PostpartumConcerns from "./PostpartumConcerns";
import Plan, { paymentPlans, PaymentPlans } from 'survey/v1/Plan';
import BuildingPlan from 'survey/v1/BuildingPlan';
import BirthNumber from "survey/v1/BirthNumber";
import CMvPhysTherapy from "./CMvPhysTherapy";
import { getPains } from "./util";
import PRBPPrepInfo1 from "./PRBPPrepInfo1";
import PRBPPrepInfo2 from "./PRBPPrepInfo2";
import BirthRecoveryThought1 from "./BirthRecoveryThought1";
import BirthRecoveryThought2 from "./BirthRecoveryThought2";
import PPRecovery1 from 'survey/v1/PPRecovery1';
import PPRecovery2 from 'survey/v1/PPRecovery2';
import NumberOfPastBirths from "./NumberOfPastBirths";
import PostpartumSymptoms from "./PostpartumSymptoms";
import StickToPlanConfidence from "./StickToPlanConfidence";
import Coaching from "./Coaching";
import Session1 from "./Session1";
import Session2 from "./Session2";
import FavoriteGesture from "./FavoriteGesture";
import Email from "./Email";
import PRBPPainInfo2 from "./PRBPPainInfo2";

enum SurveyPaths {
  CurrentlyPregnant = "currently-pregnant",
  BirthBefore = "birth-before",
  ForSomeoneElse = 'for-someone-else',
  LastBirthTime = "last-birth",
  BirthType = "birth-type",
  NumberOfPastBirths = 'number-of-past-births',
  PostpartumSymptoms = 'postpartum-symptoms',
  PostpartumProgram1 = 'postpartum-program-1',
  PostpartumProgram2 = 'postpartum-program-2',
  DueDate = "due-date",
  BirthNumber = 'birth-number',
  FirstTriIssues = "first-trimester-issues",
  SecondTriIssues = "second-trimester-issues",
  ThirdTriIssues = "third-trimester-issues",
  PhysicalActivityLevel = "physical-activity-level",
  PhysActivities = "physical-activities",
  PhysTherapyBefore = "physical-therapy-before",
  CMvPhysTherapy = 'chelsea-method-versus-physical-therapy',
  PRBPPainInfo1 = 'pain-relief-1',
  PRBPPainInfo2 = 'pain-relief-2',
  PlannedDeliveryType = 'planned-delivery-type',
  PRBPPrepInfo1 = 'birth-prep-1',
  PRBPPrepInfo2 = 'birth-prep-2',
  BirthRecoveryThought1 = "birth-recovery-thought-1",
  BirthRecoveryThought2 = "birth-recovery-thought-2",
  PostpartumConcerns = "postpartum-concerns",
  PPRecovery1 = "postpartum-recovery-1",
  PPRecovery2 = "postpartum-recovery-2",
  StickToPlanConfidence = 'confidence',
  Coaching = 'coaching',
  Session1 = 'sessions-1',
  Session2 = 'sessions-2',
  FavoriteGesture = "gesture",
  Email = "email",
  BuildingPlan = "building-plan",
  Plan = "plan",
}

const SurveyFrame = styled(Box)`
  background: ${props => props.theme.colors.lightYellow};
  width: 100%;
  min-height: 100%;
  padding-bottom: 80px;
`;

const ipdata = new IPData(
  "476d017cee7a58bb876f8450562ed6f516524014a05a12c69debcb64"
);
const db = firebaseApp.db;

const getLocalResponse = (): SurveyResponse | void => {
  try {
    return JSON.parse(
      localStorage.getItem("surveyResponse") || ""
    ) as SurveyResponse;
  } catch (err) {}
};

const overwriteMerge = (destinationArray: any[], sourceArray: any[]) =>
  sourceArray;

const uniqueMerge = (destinationArray: any[], sourceArray: any[]) => {
  return destinationArray.concat(sourceArray).filter((val, index, self) => self.indexOf(val) === index);
}

const SurveyIndex = () => {
  const [surveyResponse, setSurveyResponse] = useState<SurveyResponse>();
  // const [surveyResponse, setSurveyResponse] = useState<SurveyResponse>();
  const history = useHistory();

  useEffect(() => {
    const run = async () => {
      const localSurveyResponse = getLocalResponse();
      if (localSurveyResponse) {
        setSurveyResponse(localSurveyResponse);
      } else {
        // create new response on server
        const data = await ipdata.lookup();
        const response = await db
          .collection("surveys/v1/responses")
          .add({ ipData: data });
        setSurveyResponse({ id: response.id, ipData: data });
      }
    };
    run();
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scroll(0,0);
    });
    return unlisten;
  });

  useEffect(() => {
    const run = async () => {
      if (surveyResponse) {
        localStorage.setItem("surveyResponse", JSON.stringify(surveyResponse));
        await db
          .doc(`surveys/v1/responses/${surveyResponse.id}`)
          .update(surveyResponse);
      }
    };
    run();
  }, [surveyResponse]);

  const handleSubmitCurrentlyPregnant = async (value: boolean) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(surveyResponse, {
          currentPregnancy: { isCurrentlyPregnant: value },
        })
      );
    if (value) {
      history.push(`/survey/${SurveyPaths.DueDate}`);
    } else {
      history.push(`/survey/${SurveyPaths.BirthBefore}`);
    }
  };

  const handleSubmitDueDate = async (date?: string) => {
    if (!date) {
      date = surveyResponse?.currentPregnancy?.dueDate;
    }
    const weeksFromDueDate = moment(date).subtract(moment.now()).weeks();
    const weeks = 36 - weeksFromDueDate;
    let trimester;
    if (weeksFromDueDate < 0) {
      // past due date
      trimester = 3;
    } else if (weeksFromDueDate >= 0 && weeksFromDueDate < 12) {
      // third trimester
      trimester = 3;
    } else if (weeksFromDueDate >= 12 && weeksFromDueDate < 24) {
      // second trimester
      trimester = 2;
    } else if (weeksFromDueDate >= 24) {
      // first trimester
      trimester = 1;
    }
    surveyResponse &&
      setSurveyResponse(
        deepmerge(surveyResponse, {
          currentPregnancy: { dueDate: date, weeks, trimester },
        })
      );
    history.push(`/survey/${SurveyPaths.BirthNumber}`);
  };

  const handleSubmitBirthNumber = async (key: string) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(surveyResponse, { currentPregnancy: { birthNumber: key } })
      );
    history.push(`/survey/${SurveyPaths.PhysicalActivityLevel}`);
  };

  const handleSubmitPhysicalActivityLevel = async (key: string) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(surveyResponse, { physActivityLevel: key as ActivityLevelEnum })
      );
    history.push(`/survey/${SurveyPaths.PhysActivities}`);
  }

  const handleSubmitPhysActivities = async (val?: string[]) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(
          surveyResponse,
          { physActivities: val as ActivitiesEnum[] },
          { arrayMerge: overwriteMerge }
        )
      );
    // skip physical therapy
    // history.push(`/survey/${SurveyPaths.PhysTherapyBefore}`);
    if (surveyResponse?.currentPregnancy?.isCurrentlyPregnant) {
      switch (surveyResponse?.currentPregnancy?.trimester) {
        case 1: {
          history.push(`/survey/${SurveyPaths.FirstTriIssues}`);
          break;
        }
        case 2: {
          history.push(`/survey/${SurveyPaths.SecondTriIssues}`);
          break;
        }
        case 3: {
          history.push(`/survey/${SurveyPaths.ThirdTriIssues}`);
          break;
        }
        default: {
        }
      }
    } else {
      history.push(`/survey/${SurveyPaths.PostpartumSymptoms}`);
    }
  };

  const handleSubmitCMvPhysTherapy = async () => {
    if (surveyResponse?.currentPregnancy?.isCurrentlyPregnant) {
      switch (surveyResponse?.currentPregnancy?.trimester) {
        case 1: {
          history.push(`/survey/${SurveyPaths.FirstTriIssues}`);
          break;
        }
        case 2: {
          history.push(`/survey/${SurveyPaths.SecondTriIssues}`);
          break;
        }
        case 3: {
          history.push(`/survey/${SurveyPaths.ThirdTriIssues}`);
          break;
        }
        default: {
        }
      }
    } else {
      history.push(`/survey/${SurveyPaths.PostpartumSymptoms}`);
    }
  }

  const handleSubmitBirthBefore = async (key: string) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(surveyResponse, {
          lastBirth: { hasGivenBirthBefore: key === "yes" ? true : false },
        })
      );
    if (key === "yes") {
      history.push(`/survey/${SurveyPaths.LastBirthTime}`);
    } else {
      history.push(`/survey/${SurveyPaths.ForSomeoneElse}`);
    }
  };

  const handleSubmitLastBirth = async (date?: string) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(surveyResponse, { lastBirth: { date } })
      );
    history.push(`/survey/${SurveyPaths.BirthType}`);
  };

  const handleSubmitBirthType = async (key: string) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(surveyResponse, { lastBirth: { deliveryType: key } })
      );
    history.push(`/survey/${SurveyPaths.NumberOfPastBirths}`);
  };

  const handleSubmitNumberOfPastBirths = async (key: string) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(surveyResponse, { lastBirth: { birthNumber: key } })
      );
    history.push(`/survey/${SurveyPaths.PhysicalActivityLevel}`);
  };

  const handleSubmitFirstTriIssues = async (val?: string[]) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(
          surveyResponse,
          { currentPregnancy: { symptoms: val } },
          { arrayMerge: overwriteMerge }
        )
      );
   
  };

  const handleSubmitSecondTriIssues = async (val?: string[]) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(
          surveyResponse,
          { currentPregnancy: { symptoms: val } },
          { arrayMerge: overwriteMerge }
        )
      );
    const pains = getPains(surveyResponse);
    if (pains.length > 0) {
      history.push(`/survey/${SurveyPaths.PRBPPainInfo1}`);
    } else {
      history.push(`/survey/${SurveyPaths.PlannedDeliveryType}`);
    }
  };

  const handleSubmitThirdTriIssues = async (val?: string[]) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(
          surveyResponse,
          { currentPregnancy: { symptoms: val } },
          { arrayMerge: overwriteMerge }
        )
      );
    history.push(`/survey/${SurveyPaths.PRBPPainInfo1}`);
  };

  const handleSubmitPostpartumSymptoms = async (val?: string[]) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(
          surveyResponse,
          { postpartumSymptoms: val as (keyof typeof postpartumSymptoms)[]},
          { arrayMerge: overwriteMerge }
        )
      );
    history.push(`/survey/${SurveyPaths.PPRecovery1}`);
  }

  const handleSubmitPRBPPainFocus1 = async (val?: boolean) => {
    let interestedIn = surveyResponse?.interestedIn || [];
    if (interestedIn) {
      if (val) {
        interestedIn = uniqueMerge(interestedIn, [CMInterestsEnum.PRBPPainFocus]);
      } else {
        interestedIn = interestedIn.filter((val) => val !== CMInterestsEnum.PRBPPainFocus);
      }
    }

    surveyResponse && setSurveyResponse(
      deepmerge(
        surveyResponse,
        { interestedIn },
        { arrayMerge: overwriteMerge }
      )
    );

    if (val) {
      history.push(`/survey/${SurveyPaths.PRBPPainInfo2}`);
    } else {
      history.push(`/survey/${SurveyPaths.PlannedDeliveryType}`);
    }
  }

  const handleSubmitPRBPPainFocus2 = async () => {
    history.push(`/survey/${SurveyPaths.PlannedDeliveryType}`);
  }

  const handleSubmitPlannedDeliveryType = async (val?: string) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(surveyResponse, { currentPregnancy: { plannedDeliveryType: val } })
      );
    if (val === BirthTypeEnum.Vaginal) {
      history.push(`/survey/${SurveyPaths.PRBPPrepInfo1}`);
    } else {
      history.push(`/survey/${SurveyPaths.BirthRecoveryThought1}`);
    }
  }

  const handleSubmitPRBPPrepFocus1 = async (val?: boolean) => {
    let interestedIn = surveyResponse?.interestedIn || [];
    if (interestedIn) {
      if (val) {
        interestedIn = uniqueMerge(interestedIn, [CMInterestsEnum.PRBPPrepFocus]);
      } else {
        interestedIn = interestedIn.filter((val) => val !== CMInterestsEnum.PRBPPrepFocus);
      }
    }

    surveyResponse && setSurveyResponse(
      deepmerge(
        surveyResponse,
        { interestedIn },
        { arrayMerge: overwriteMerge }
      )
    );
    if (val) {
      history.push(`/survey/${SurveyPaths.PRBPPrepInfo2}`);
    } else {
      history.push(`/survey/${SurveyPaths.BirthRecoveryThought1}`);
    }
  }

  const handleSubmitPRBPPrepFocus2 = async () => {
    history.push(`/survey/${SurveyPaths.BirthRecoveryThought1}`);
  }

  const handleSubmitPhysTherapyBefore = async (val?: boolean) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(surveyResponse, { beenToPhysicalTherapy: val })
      );
    history.push(`/survey/${SurveyPaths.CMvPhysTherapy}`);
  };

  const handleSubmitBirthRecoveryThought1 = async (val?: string) => {
    surveyResponse && setSurveyResponse(
      deepmerge(
        surveyResponse,
        { currentPregnancy: { birthRecoveryThoughtLevel: val }}
      )
    );
    history.push(`/survey/${SurveyPaths.BirthRecoveryThought2}`);
  }

  const handleSubmitBirthRecoveryThought2 = async () => {
    history.push(`/survey/${SurveyPaths.PostpartumConcerns}`);
  }

  const handleSubmitPostpartumConcerns = async (val?: string[]) => {
    surveyResponse &&
      setSurveyResponse(
        deepmerge(
          surveyResponse,
          { currentPregnancy: { postpartumConcerns: val } },
          { arrayMerge: overwriteMerge }
        )
      );
    history.push(`/survey/${SurveyPaths.PPRecovery1}`);
  }

  const handleSubmitPPRecovery1 = async (val?: boolean) => {
    let interestedIn = surveyResponse?.interestedIn || [];
    if (interestedIn) {
      if (val) {
        interestedIn = uniqueMerge(interestedIn, [CMInterestsEnum.PostpartumRecoveryProgram]);
      } else {
        interestedIn = interestedIn.filter((val) => val !== CMInterestsEnum.PostpartumRecoveryProgram);
      }
    }
    
    surveyResponse && setSurveyResponse(
      deepmerge(
        surveyResponse,
        { interestedIn },
        { arrayMerge: overwriteMerge }
      )
    );

    if (val) {
      history.push(`/survey/${SurveyPaths.PPRecovery2}`);
    } else {
      history.push(`/survey/${SurveyPaths.StickToPlanConfidence}`);
    }
  };

  const handleSubmitPPRecovery2 = async (val?: boolean) => {
    history.push(`/survey/${SurveyPaths.StickToPlanConfidence}`);
  };

  const handleSubmitPlanConfidence = async (val?: string) => {
    surveyResponse && setSurveyResponse(
      deepmerge(
        surveyResponse,
        { confidenceLevel: val as (keyof typeof confidenceLevel) }
      )
    );
    history.push(`/survey/${SurveyPaths.Coaching}`);
  }

  const handleSubmitCoaching = async (val?: boolean) => {
    surveyResponse && setSurveyResponse(
      deepmerge(
        surveyResponse,
        { coachingHelpful: val }
      )
    );
    history.push(`/survey/${SurveyPaths.Session1}`);
  }

  const handleSubmitSession1 = async (val?: boolean) => {
    let interestedIn = surveyResponse?.interestedIn || [];
    if (interestedIn) {
      if (val) {
        interestedIn = uniqueMerge(interestedIn, [CMInterestsEnum.Sessions]);
      } else {
        interestedIn = interestedIn.filter((val) => val !== CMInterestsEnum.Sessions);
      }
    }
    surveyResponse && setSurveyResponse(
      deepmerge(
        surveyResponse,
        { interestedIn },
        { arrayMerge: overwriteMerge }
      )
    );
    if (val) {
      history.push(`/survey/${SurveyPaths.Session2}`);
    } else {
      history.push(`/survey/${SurveyPaths.FavoriteGesture}`);
    }
  }

  const handleSubmitFavoriteGesture = async (val?: string) => {
    surveyResponse && setSurveyResponse(
      deepmerge(
        surveyResponse,
        { favoriteGesture: val as (keyof typeof gestures) }
      )
    );
    history.push(`/survey/${SurveyPaths.Email}`);
  }

  const handleSubmitSession2 = async () => {
    history.push(`/survey/${SurveyPaths.FavoriteGesture}`);
  }

  const handleSubmitEmail = async (email?: string, name?: string) => {
    surveyResponse && setSurveyResponse(
      deepmerge(
        surveyResponse,
        { email, name }
      )
    );
    history.push(`/survey/${SurveyPaths.BuildingPlan}`);
  }

  const handleSubmitBuildingPlan = async (val?: string[]) => {
    surveyResponse && setSurveyResponse(
      deepmerge(
        surveyResponse,
        { seenConvincers: val },
        { arrayMerge: overwriteMerge }
      )
    );
    history.push(`/survey/${SurveyPaths.Plan}`);
  }

  const handleSubmitPlan = async (val: PaymentPlans) => {
    window.location.href = paymentPlans[val].url;
  }

  let { path } = useRouteMatch();
  return (
    <SurveyFrame>
      <Box pt={4}>
        <Box mb={4}>
          <Logo />
        </Box>
        <Box>
          <Switch>
            <Route
              path={[
                `${path}/start`,
                `${path}/${SurveyPaths.CurrentlyPregnant}`,
              ]}
            >
              <CurrentlyPregnant
                onSubmit={handleSubmitCurrentlyPregnant}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.DueDate}`}>
              {surveyResponse && <DueDate
                onSubmit={handleSubmitDueDate}
                defaultResponse={surveyResponse}
              />}
            </Route>
            <Route path={`${path}/${SurveyPaths.BirthNumber}`}>
              {surveyResponse && <BirthNumber
                onSubmit={handleSubmitBirthNumber}
                defaultResponse={surveyResponse}
              />}
            </Route>
            <Route path={`${path}/${SurveyPaths.PhysicalActivityLevel}`}>
              {surveyResponse && <PhysActivityLevel
                onSubmit={handleSubmitPhysicalActivityLevel}
                defaultResponse={surveyResponse}
              />}
            </Route>
            <Route path={`${path}/${SurveyPaths.PhysActivities}`}>
              <PhysActivities
                onSubmit={handleSubmitPhysActivities}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.PhysTherapyBefore}`}>
              <PhysTherapyBefore
                onSubmit={handleSubmitPhysTherapyBefore}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.CMvPhysTherapy}`}>
              <CMvPhysTherapy
                onSubmit={handleSubmitCMvPhysTherapy}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.PostpartumSymptoms}`}>
              <PostpartumSymptoms
                onSubmit={handleSubmitPostpartumSymptoms}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.FirstTriIssues}`}>
              <FirstTriIssues
                onSubmit={handleSubmitFirstTriIssues}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.SecondTriIssues}`}>
              <SecondTriIssues
                onSubmit={handleSubmitSecondTriIssues}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.ThirdTriIssues}`}>
              <ThirdTriIssues
                onSubmit={handleSubmitThirdTriIssues}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.PRBPPainInfo1}`}>
              <PRBPPainInfo1
                onSubmit={handleSubmitPRBPPainFocus1}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.PRBPPainInfo2}`}>
              <PRBPPainInfo2
                onSubmit={handleSubmitPRBPPainFocus2}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.PlannedDeliveryType}`}>
              <PlannedDeliveryType
                onSubmit={handleSubmitPlannedDeliveryType}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.PRBPPrepInfo1}`}>
              <PRBPPrepInfo1
                onSubmit={handleSubmitPRBPPrepFocus1}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.PRBPPrepInfo2}`}>
              <PRBPPrepInfo2
                onSubmit={handleSubmitPRBPPrepFocus2}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.BirthRecoveryThought1}`}>
              <BirthRecoveryThought1
                onSubmit={handleSubmitBirthRecoveryThought1}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.BirthRecoveryThought2}`}>
              <BirthRecoveryThought2
                onSubmit={handleSubmitBirthRecoveryThought2}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.PostpartumConcerns}`}>
              <PostpartumConcerns
                onSubmit={handleSubmitPostpartumConcerns}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.PPRecovery1}`}>
              <PPRecovery1
                onSubmit={handleSubmitPPRecovery1}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.PPRecovery2}`}>
              <PPRecovery2
                onSubmit={handleSubmitPPRecovery2}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.BirthBefore}`}>
              <BirthBefore
                onSubmit={handleSubmitBirthBefore}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.LastBirthTime}`}>
              <LastBirth
                onSubmit={handleSubmitLastBirth}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.BirthType}`}>
              <BirthTypeComponent
                onSubmit={handleSubmitBirthType}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.NumberOfPastBirths}`}>
              <NumberOfPastBirths
                onSubmit={handleSubmitNumberOfPastBirths}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.StickToPlanConfidence}`}>
              <StickToPlanConfidence
                onSubmit={handleSubmitPlanConfidence}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.Coaching}`}>
              <Coaching
                onSubmit={handleSubmitCoaching}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.Session1}`}>
              <Session1
                onSubmit={handleSubmitSession1}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.Session2}`}>
              <Session2
                onSubmit={handleSubmitSession2}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.FavoriteGesture}`}>
              <FavoriteGesture
                onSubmit={handleSubmitFavoriteGesture}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.Email}`}>
              <Email
                onSubmit={handleSubmitEmail}
                defaultResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.BuildingPlan}`}>
              <BuildingPlan
                onSubmit={handleSubmitBuildingPlan}
                surveyResponse={surveyResponse}
              />
            </Route>
            <Route path={`${path}/${SurveyPaths.Plan}`}>
              <Plan surveyResponse={surveyResponse || {}} onSubmit={handleSubmitPlan} />
            </Route>
          </Switch>
        </Box>
      </Box>
    </SurveyFrame>
  );
};

export default SurveyIndex;
