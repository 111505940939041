
import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse from "./survey-response";

const Session1 = (props: { onSubmit: (response: boolean) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit } = props;

  const content = <div>
    <p>In addition to coaching, members get discounted rates on private, virtual Physical Therapy appointments called Sessions.</p>
    <p>Sessions are optional, but they’re the perfect compliment to our streaming programs. They’re great for moms who want one-on-one care from a Physical Therapist.</p>
    <p>Would you like to hear a little more about Sessions?</p>
  </div>

  return <Box>
    <MultipleChoice
      question={
        <Box>
          {content}
        </Box>
      }
      onChange={(selected) => {
        const response = selected[0] === 'yes' ? true : false;
        onSubmit(response)
      }}
      choices={[
        { id: 'yes', text: `i’m interested` },
        { id: 'no', text: 'not now' },
      ]}
    />
  </Box> 
}

export default Session1;