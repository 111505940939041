import Box from "components/Box"
import DateChoice from "survey/components/DateChoice";
import SurveyResponse from 'survey/v1/survey-response';

const LastBirth = (props: { onSubmit: (date?: string) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  let defaultDate;
  if (defaultResponse?.lastBirth?.date !== undefined) {
    defaultDate = defaultResponse?.lastBirth?.date;
  }

  return <Box>
    <DateChoice
      question="When was your last birth?"
      onSubmit={onSubmit}
      defaultValue={defaultDate}
    />
  </Box> 
}

export default LastBirth;