import Box from "components/Box";
import Button from "survey/components/Button";
import { FormWrap } from "survey/components/Form";
import SurveyResponse from "./survey-response";

const CMvPhysTherapy = (props: { onSubmit: () => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit } = props;

  return (
    <FormWrap fontSize={3} mb={3} color="black" m="0 auto" display="flex" alignItems="center" flexDirection="column">
      <Box mb={3}>
        <p>
          Physical therapy can cost hundreds of dollars a month and usually requires visiting a clinic in-person.
        </p>
        <p>
          Chelsea Method isn’t Physical Therapy, but our expert physical therapists design our classes using principles that have been proven to work for most women—backed by science.
        </p>
        <p>
          Our streaming video library gives you 24/7 access to all our content at any time, from the convenience of your home.
        </p>
      </Box>
      <Button buttonType="submit" onClick={onSubmit}>NEXT</Button>
    </FormWrap>
  ); 
}

export default CMvPhysTherapy;