const theme = {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [
    12, 14, 16, 18, 21, 24, 32, 48, 64
  ],
  colors: {
    primary: '#7E5CDF',
    white: '#FFF',
    black90: '#000000CC',
    black60: '#00000099',
    black50: '#00000077',
    lightPurple: '#C0AAFE',
    lightYellow: '#FFF1DE',
    border: '#00000022',
  },
  space: [
    0, 4, 8, 16, 24, 32, 64, 128, 256
  ],
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)'
  },
  variants: {
  },
  text: {
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    }
  }
};

theme.fontSizes.button = theme.fontSizes[3];

export default theme;