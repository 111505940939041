import Button from "survey/components/Button";
import Box from "components/Box"
import { useState } from "react";
import MultipleChoice from "survey/components/MultipleChoice";
import { ContinueWrap } from "survey/components/Form";
import SurveyResponse, { secondTriSymptoms } from "./survey-response";
import { useEffect } from "react";
import { useRef } from "react";

const SecondTriIssues = (props: { onSubmit: (keys: string[]) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;
  const [selected, setSelected] = useState<string[]>();
  const defaultSelection = useRef<string[] | undefined>();

  useEffect(() => {
    if (defaultResponse?.currentPregnancy?.symptoms !== undefined) {
      defaultSelection.current = defaultResponse?.currentPregnancy?.symptoms;
      setSelected(defaultResponse?.currentPregnancy?.symptoms);
    }
  }, [defaultResponse]);

  return (
    <Box>
      <MultipleChoice
        allowMultipleSelected
        question="Are you experiencing any of these common second trimester issues?"
        onChange={(selected) => { setSelected(selected); }}
        choices={Object.entries(secondTriSymptoms).map(s => ({ id: s[0], text: s[1].text }))}
        defaultSelection={defaultSelection.current}
      />
      <ContinueWrap m="0 auto" display="flex" justifyContent="center">
        <Button
          buttonType="submit"
          onClick={() => selected && onSubmit(selected)}
        >
          continue
        </Button>
      </ContinueWrap>
    </Box>
  );
}

export default SecondTriIssues;