import Box from "components/Box";
import React, { useRef, useState } from "react";
import styled from 'styled-components';
import { useEffect } from "react";
import { ReactComponent as ImgCosmo } from "assets/images/reviews/cosmopolitan.svg";
import { ReactComponent as ImgPopSugar } from "assets/images/reviews/popsugar.svg";
import { ReactComponent as ImgWomensRunning } from "assets/images/reviews/womensrunning.svg";
import { ReactComponent as ImgBeachBody } from "assets/images/reviews/beachbody.svg";
import { ReactComponent as ImgLaurel } from "assets/images/reviews/laurel.svg";
import { ReactComponent as ImgStars } from "assets/images/reviews/stars.svg";
import { ReactComponent as ImgQuote } from "assets/testimonial.svg";
import { FormWrap } from "survey/components/Form";
import SurveyResponse, { ActivitiesEnum, ActivityLevelEnum, BirthTypeEnum, SymptomsEnum } from "./survey-response";
import { getPains } from "./util";
import { intersection } from "lodash";
import { uniq } from "lodash";
import moment from "moment";
import FadeIn from "components/FadeIn";

const SProgress = styled(Box)`
  @keyframes progress {
    0% {
      right: 100%;
    }
    20% {
      right: 90%;
    }
    40% {
      right: 50%;
    }
    60% {
      right: 30%;
    }
    80% {
      right: 10%;
    }
    100% {
      right: 0%;
    }
  }
  animation: progress 30s;
  right: 0%;
  overflow: hidden;
`;

const SProgressGradient = styled(Box)`
  @keyframes gradient {
    0% {
      background-position-x: 0%;
    }
    100% {
      background-position-x: -200%;
    }
  }
  background: linear-gradient(90deg, #7E5CDF, #FF619A, #7E5CDF, #FF619A);
  position: absolute;
  top: 0;
  width: 300%;
  bottom: 0;
  left: 0;
  animation: gradient 5s linear infinite;
  z-index: 100;
`;

const Testimonial = styled(Box)`
  position: relative;
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.space[4]}px;
  box-shadow: rgba(0,0,0,0.03) 0 2px 8px;
  border-radius: 3px;
`;

enum ConvincerStep {
  Step1,
  Step2,
  Step3
}

const BuildingPlan = (props: { onSubmit: (seenConvincers: string[]) => any, surveyResponse?: SurveyResponse }) => {
  const { surveyResponse: res, onSubmit } = props;
  const progress = useRef<HTMLDivElement>(null);
  const [step, setStep] = useState<ConvincerStep>(ConvincerStep.Step1);
  const seenConvincers = useRef<Array<string>>([]);
  const [loadingText, setLoadingText] = useState<string>("Compiling answers...");

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setStep(ConvincerStep.Step2);
    }, 12000);
    const timer2 = setTimeout(() => {
      setStep(ConvincerStep.Step3);
      setLoadingText("Finishing up...");
    }, 22000);
    const timer3 = setTimeout(() => {
      onSubmit(uniq(seenConvincers.current));
    }, 32000);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTestimonial = (text: string, name: string, title: string, img?: string) => {
    return (
      <Testimonial>
        <Box position="absolute" left="24px" top="16px" zIndex={1}>
          <ImgQuote width="40px" />
        </Box>
        <Box textAlign="left" fontSize={[2, 3]} mb={3} pl="56px" lineHeight="1.6" dangerouslySetInnerHTML={{ __html: text }} />
        <Box textAlign="left" fontSize={[1, 2]} pl="56px"><b>{name}</b><Box as="span" color="black60"> — {title}</Box></Box>
      </Testimonial>
    );
  }  

  const convincers = {
    testimonial: {
      pregnant: {
        pain: renderTestimonial(
          "I woke up the next morning not feeling stiff for the first time in months. The techniques from the Pain Relief and Birth Prep class really reduced the back pain that started in my second trimester.",
          "Lana",
          "Chelsea Method Member"
        ),
        prep: renderTestimonial(
          "The Pain Relief and Birth Prep class made me feel so confident. I learned how to do things that felt good for my body before and during my labor.",
          "Hillary",
          "Chelsea Method Member"
        ),
      },
      postpartum: {
        immediate: renderTestimonial(
          "Chelsea Method is amazing for moms to start in the first few weeks after they deliver. It’s such a critical period of time. Even if there are no obvious issues right away, this program helps reduce the likelyhood of developing longterm issues. Highly recommend!",
          "Molly",
          "Physical Therapist"
        ),
        returnToExercise: renderTestimonial(
          "Coming back to your normal fitness routine postpartum can be so overwhelming, especially if you've had any kind of injury. I felt safe and confident with Chelsea Method.",
          "Michelle",
          "Chelsea Method Member"
        ),
        incontinence: renderTestimonial(
          "With the birth of my second child I was worried about incontinence returning and that I wouldn’t have time for in-person physical therapy. After completing a Chelsea Method Recovery Program I can say confidently that it’s comparable to doing in-person PT, if not better! I loved being able to watch and rewatch videos at my own pace on my own schedule.",
          "Vicki",
          "Chelsea Method Member"
        ),
        convenience: renderTestimonial(
          "After my son was born, I didn’t have the energy or the time to get out of the house for any self care. You can do it all within the comfort of your home, whenever it’s convenient for you!!! It’s brilliant!",
          "Denise",
          "Chelsea Method Member"
        ),
        assortedPain: renderTestimonial(
          "I was dealing with all sorts of pain—knee, feet, back—and their approach to building strength helped me know that I could trust them as I progressed in the program. I already feel stronger than I was before getting pregnant!",
          "Sara",
          "Chelsea Method Member"
        ),
        backPain: renderTestimonial(
          "I had a lot of lower back pain after my daughter was born and I found I was doing the “mommy posture” they talked about. Once I corrected my posture with the help of their videos my back pain was completely gone.",
          "Amy",
          "Chelsea Method Member"
        ),
        returnToExerciseAndCSection: renderTestimonial(
          "I was concerned with returning to running after I had a c-section, even when I was cleared by my doctor. This program gave me confidence. I’m very grateful to have found this program!",
          "Amy",
          "Chelsea Method Member"
        ),
        furtherFromBirth: renderTestimonial(
          "I found Chelsea method 8 months after the birth of my son and I wish I would have known about them sooner. Being 8 months out I still decided to purchase the program because I was having pain exercising trying to lose excess baby weight. I didn’t follow the calendar exactly, but was able to use the principles taught in the videos and apply them to my everyday routine. Following their directions helped me to exercise again without pain.",
          "Alexis",
          "Chelsea Method Member”"
        ),
        dance: renderTestimonial(
          "Our dance company works with lots of pre and postnatal women who feel very nervous about how to prepare for birth and heal properly afterwards. We are extremely impressed with the approach Chelsea Method takes with this process and have recommended it to many of our clients. It has been wonderful to see our women gain strength more quickly and safely with Chelsea Method and feel more confident about resuming an exercise regimen.",
          "Mahri",
          "Founder of Body Conceptions"
        ),
        pilates: renderTestimonial(
          "I’m a Pilates practitioner who specializes in working with the perinatal population. Chelsea Method is amazing for my clients. It is astounding how focusing on these basic breathing and movement patterns early on post childbirth can aid in a pain free return to healthy workouts!",
          "Chrysetta",
          "Pilates Instructor"
        ),
        expert: renderTestimonial(
          "As a pelvic floor specialist there is nothing else as comprehensive, thorough and safe for postpartum moms. I’ve recommended this program to all my postpartum patients with great success. I trust this program to help with all new moms and I can’t recommended it enough!",
          "Tina",
          "Pelvic Floor Physical Therapist"
        ),
      },
    },
    ratings: (
      <Testimonial pt="0px" pb="0px">
        <Box display="flex" flexDirection={["column", "row"]} alignItems="center" justifyContent="space-between" width="100%">
          <Box flexBasis={["100%", "20%"]}>
            <Box width={["100px", "80px"]}>
              <ImgCosmo width="100%" />
            </Box>
            <Box width={["120px", "100px"]}>
              <ImgPopSugar width="100%" />
            </Box>
          </Box>
          <Box flexBasis={["100%", "40%"]}>
            <Box position="relative" maxWidth="240px">
              <ImgLaurel width="100%" height="120px" />
              <Box position="absolute" top="0px" right="0px" bottom="0px" left="0px" display="flex" flexDirection="column" justifyContent="center" alignItems="center" pb="20px">
                <Box fontSize={4} fontWeight={600} lineHeight="1">4.9/5</Box>
                <Box width={["30%", "50%"]}>
                  <ImgStars width="100%" />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box flexBasis={["100%", "20%"]}>
            <Box width={["100px", "80px"]}>
              <ImgWomensRunning width="100%" />
            </Box>
            <Box width={["100px", "80px"]}>
              <ImgBeachBody width="100%" />
            </Box>
          </Box>
        </Box>
      </Testimonial>
    ),
  };

  const isActive = (res?: SurveyResponse): boolean => {
    return intersection(res?.physActivityLevel, [ActivityLevelEnum.Daily, ActivityLevelEnum.Weekly]).length > 0;
  }

  const renderConvincers = () => {
    let c;
    if (!res) return;
    switch (step) {
      case ConvincerStep.Step1: {
        if (res?.currentPregnancy?.isCurrentlyPregnant) {
          // pregnant
          if (getPains(res).length > 0) {
            seenConvincers.current.push("convincers.testimonial.pregnant.pain");
            c = convincers.testimonial.pregnant.pain;
          } else if (res?.currentPregnancy?.plannedDeliveryType === BirthTypeEnum.Vaginal) {
            seenConvincers.current.push("convincers.testimonial.pregnant.prep");
            c = convincers.testimonial.pregnant.prep;
          } else {
            seenConvincers.current.push("convincers.testimonial.pregnant.pain");
            c = convincers.testimonial.pregnant.pain;
          }
        } else {
          // not pregnant
          if (res?.physActivities?.includes(ActivitiesEnum.Running) && res?.lastBirth?.deliveryType === BirthTypeEnum.CSection) {
            seenConvincers.current.push("convincers.testimonial.postpartum.returnToExerciseAndCSection");
            c = convincers.testimonial.postpartum.returnToExerciseAndCSection
          } else if (isActive(res) && res?.physActivities?.includes(ActivitiesEnum.Dance)) {
            seenConvincers.current.push("convincers.testimonial.postpartum.dance");
            c = convincers.testimonial.postpartum.dance;
          } else if (isActive(res) && res?.physActivities?.includes(ActivitiesEnum.Pilates)) {
            seenConvincers.current.push("convincers.testimonial.postpartum.pilates");
            c = convincers.testimonial.postpartum.pilates;
          } else if (res?.postpartumSymptoms?.includes(SymptomsEnum.Leaking)) {
            seenConvincers.current.push("convincers.testimonial.postpartum.incontinence");
            c = convincers.testimonial.postpartum.incontinence;
          } else if (res?.postpartumSymptoms?.includes(SymptomsEnum.BackPain)) {
            seenConvincers.current.push("convincers.testimonial.postpartum.backPain");
            c = convincers.testimonial.postpartum.backPain;
          } else if (moment().diff(moment(res?.lastBirth?.date), 'months') > 6) {
            seenConvincers.current.push("convincers.testimonial.postpartum.furtherFromBirth");
            c = convincers.testimonial.postpartum.furtherFromBirth;
          } else {
            seenConvincers.current.push("convincers.testimonial.postpartum.convenience");
            c = convincers.testimonial.postpartum.convenience;
          }
        }
        break;
      }
      case ConvincerStep.Step2: {
        if (res?.currentPregnancy?.isCurrentlyPregnant) {
          // pregnant
          c = convincers.testimonial.postpartum.immediate;
          // setSeenConvincers(seenConvincers => [...seenConvincers, "convincers.testimonial.postpartum.immediate"]);
          seenConvincers.current.push("convincers.testimonial.postpartum.immediate");
        } else {
          // not pregnant
          if (getPains(res).includes(SymptomsEnum.BackPain) && res?.lastBirth?.deliveryType === BirthTypeEnum.CSection) {
            seenConvincers.current.push("convincers.testimonial.postpartum.assortedPain");
            c = convincers.testimonial.postpartum.assortedPain;
          } else if (moment().diff(moment(res?.lastBirth?.date), 'weeks') < 6) {
            seenConvincers.current.push("convincers.testimonial.postpartum.immediate");
            c = convincers.testimonial.postpartum.immediate;
          } else {
            seenConvincers.current.push("convincers.testimonial.postpartum.expert");
            c = convincers.testimonial.postpartum.expert;
          }
        }
        break
      }
      case ConvincerStep.Step3: {
        seenConvincers.current.push("convincers.ratings");
        c = convincers.ratings;
        break;
      }
    }
    return <FadeIn key={step} delay={200}>{c}</FadeIn>
  }

  return (
    <FormWrap m="0 auto">
      <Box mb={3} fontSize={3}>{loadingText}</Box>
      <Box
        width="100%"
        height="6px"
        backgroundColor="#F9DBB3"
        position="relative"
        mb={4}
      >
        <SProgress
          ref={progress}
          position="absolute"
          left="0"
          top="0"
          bottom="0"
        >
          <SProgressGradient />
        </SProgress>
      </Box>
      <FadeIn delay={100}>{renderConvincers()}</FadeIn>
    </FormWrap>
  );
}

export default BuildingPlan;