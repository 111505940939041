import intersection from 'lodash/intersection';
import moment from 'moment';
import SurveyResponse, { BirthTypeEnum, CMContentEnum, pregnancyPains } from './survey-response';

export const getPains = (sr?: SurveyResponse): (keyof typeof pregnancyPains)[] => {
  return intersection(sr?.currentPregnancy?.symptoms, Object.keys(pregnancyPains)) as (keyof typeof pregnancyPains)[];
}

export const niceList = (array: string[], join?: string, finalJoin?: string) => {
  var arr = array.slice(0), last = arr.pop();
  join = join || ', ';
  finalJoin = array.length > 1 ? finalJoin || ' and ' : '';
  return arr.join(join) + finalJoin + last;    
};

export const recoPostpartumProgram = (res: SurveyResponse): { [key: string]: { reasonHtml: string }} => {
  const lastBirthDate = moment(res?.lastBirth?.date);
  const now = moment.now();

  if (res.currentPregnancy?.isCurrentlyPregnant) {
    if (res.currentPregnancy.plannedDeliveryType === BirthTypeEnum.Vaginal) {
      return { [CMContentEnum.VBR]: { reasonHtml: '<p>Because you’re planning on having a vaginal birth, our <b>Vaginal Birth Recovery program</b> will be perfect for you.</p><p>But no matter what, your membership gives you 24/7 access to all our streaming content including our C-Section Recovery program.</p>' } };
    } else {
      return { [CMContentEnum.CSBR]: { reasonHtml: '<p>Because you’re planning on having a c-section, our <b>C-Section Birth Recovery program</b> will be perfect for you.</p><p>But no matter what, your membership gives you 24/7 access to all our streaming content.</p>' } };
    }
  } else if (res.lastBirth?.deliveryType === BirthTypeEnum.Vaginal) {
    if (lastBirthDate.add(6, 'weeks').isAfter(now)) {
      // recent vaginal
      return { [CMContentEnum.VBR]: { reasonHtml: '<p>Because you’ve recently had a vaginal delivery, our <b>Vaginal Birth Recovery program</b> will be perfect for you.</p>' } };
    } else {
      // further vaginal
      return { [CMContentEnum.RTE]: { reasonHtml: '<p>Because you’re more than 6 weeks out from your vaginal birth, we recommend our <b>Return to Exercise Recovery program.</b></p>' } };
    }
  } else if (res.lastBirth?.deliveryType === BirthTypeEnum.CSection) {
    if (lastBirthDate.add(8, 'weeks').isAfter(now)) {
      // recent c-section
      return { [CMContentEnum.CSBR]: { reasonHtml: '<p>Because you’ve recently had a c-section, our <b>C-Section Birth Recovery program</b> will be perfect for you.</p>' } };
    } else {
      // further c-section
      return { [CMContentEnum.RTE]: { reasonHtml: '<p>Because you’re more than 8 weeks out from your c-section, we recommend our <b>Return to Exercise Recovery program.</b></p>' } };
    }
  }
  return { [CMContentEnum.RTE]: { reasonHtml: '<p>We recommend our <b>Return to Exercise Recovery program.</b></p>' } };
}