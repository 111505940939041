import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse from "./survey-response";

const PhysTherapyBefore = (props: { onSubmit: (response: boolean) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  let defaultSelection;
  if (defaultResponse?.beenToPhysicalTherapy !== undefined) {
    defaultSelection = defaultResponse?.beenToPhysicalTherapy ? ['yes'] : ['no'];
  }

  console.log(defaultSelection);

  return <Box>
    <MultipleChoice
      question="Have you ever been to physical therapy before?"
      onChange={(selected) => {
        const response = selected[0] === 'yes' ? true : false;
        onSubmit(response)
      }}
      choices={[
        { id: 'yes', text: 'yes' },
        { id: 'no', text: 'no' },
      ]}
      defaultSelection={defaultSelection}
    />
  </Box> 
}

export default PhysTherapyBefore;