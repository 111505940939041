import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse from "./survey-response";

const CurrentlyPregnant = (props: { onSubmit: (response: boolean) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  let defaultSelection;
  if (defaultResponse?.currentPregnancy?.isCurrentlyPregnant !== undefined) {
    defaultSelection = defaultResponse?.currentPregnancy?.isCurrentlyPregnant ? ['yes'] : ['no'];
  }

  return <Box>
    <MultipleChoice
      question="Are you currently pregnant?"
      onChange={(selected) => {
        const response = selected[0] === 'yes' ? true : false;
        onSubmit(response)
      }}
      choices={[
        { id: 'yes', text: 'yes' },
        { id: 'no', text: 'no' },
      ]}
      defaultSelection={defaultSelection}
    />
  </Box> 
}

export default CurrentlyPregnant;