import Button from "survey/components/Button"
import { ReactNode, useState } from "react";
import { FormWrap } from "survey/components/Form";
import Box from "components/Box"
import { useEffect } from "react";

type MultipleChoiceProps = {
  choices: Array<{ id: string, text: string }>;
  allowMultipleSelected?: boolean;
  onChange: (selected: Array<string>) => any;
  question: ReactNode;
  columns?: boolean;
  defaultSelection?: string[];
}

const MultipleChoice = (props: MultipleChoiceProps) => {
  const { choices, question, onChange, columns, allowMultipleSelected, defaultSelection } = props;
  const [selected, setSelected] = useState<Array<string>>([]);
  let [loadedDefault, setLoadedDefault] = useState<boolean>(false);

  useEffect(() => {
    console.log(loadedDefault);
    if (defaultSelection && !loadedDefault) {
      setSelected(defaultSelection);
      setLoadedDefault(true);
    }
  }, [defaultSelection, loadedDefault]);

  const updateSelected = (newSelection: string[]) => {
    setSelected(newSelection);
    onChange(newSelection);
  };

  return (
    <Box>
      <FormWrap m="0 auto">
        <Box fontSize={3} mb={3} color="black">{question}</Box>
      </FormWrap>
      <Box
        maxWidth="320px"
        margin="0 auto"
        display="flex"
        flexDirection={columns ? "row" : "column"}
        alignItems="center"
        flexWrap={columns ? "wrap" : "nowrap"}
      >
        <FormWrap flexBasis={columns ? "50%" : "100%"}>
          {choices.map((choice) => {
            const selectedIndex = selected?.findIndex((key) => key === choice.id);
            const defaultIndex = defaultSelection?.findIndex((key) => key === choice.id);
            let index = -1;
            if (selectedIndex !== undefined && selectedIndex >= 0) {
              index = selectedIndex;
            } else if (!loadedDefault && defaultIndex !== undefined && defaultIndex >= 0) {
              index = defaultIndex;
            }
            return (
              <Box pt={1} pb={1} key={choice.id}>
                <Button
                  buttonType={index > -1 ? "filled" : "outline"}
                  onClick={() => {
                    if (allowMultipleSelected) {
                      if (index > -1) {
                        const filtered = selected.filter(
                          (key) => key !== choice.id
                        );
                        updateSelected(filtered);
                      } else {
                        updateSelected(selected.concat(choice.id));
                      }
                    } else {
                      updateSelected([choice.id]);
                    }
                  }}
                >
                  {choice.text}
                </Button>
              </Box>
            );
          })}
        </FormWrap>
      </Box>
    </Box>
  );
}

export default MultipleChoice;
