import Box from "components/Box";
import Button from "survey/components/Button";
import { FormWrap } from "survey/components/Form";
import SurveyResponse from "./survey-response";

const Session2 = (props: { onSubmit: () => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit } = props;

  return (
    <FormWrap fontSize={3} mb={3} color="black" m="0 auto" display="flex" alignItems="center" flexDirection="column">
      <Box mb={3}>
        <p>
          Sessions are virtual Physical Therapy.
        </p>
        <p>
          That means you get one-on-one care from a physical therapist without leaving home. Just book a time that works for you through our online scheduling app.
        </p>
        <p>
          They’re private, convenient, and easy to schedule. 
        </p>
        <p>
          Our PTs are experts in women’s health, pregnancy and postpartum care, and treating pelvic floor issues.
        </p>
        <p>
          Plus: members get 50% off their initial evaluation (a $75 value) and on-going discounts on any follow-up Sessions.
        </p>
      </Box>
      <Button buttonType="submit" onClick={onSubmit}>NEXT</Button>
    </FormWrap>
  ); 
}

export default Session2;