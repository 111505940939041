import Box from "components/Box";
import Button from "survey/components/Button";
import { FormWrap } from "survey/components/Form";
import SurveyResponse, { NoneLittleLot } from "./survey-response";

const CMvPhysTherapy = (props: { onSubmit: () => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  const level = defaultResponse?.currentPregnancy?.birthRecoveryThoughtLevel;
  let customText;
  if (level === NoneLittleLot.Lot) {
    customText = "Great! You’re on top of it."
  } else if (level === NoneLittleLot.Little) {
    customText = "Great! You’re already thinking about it."
  } else {
    customText = "That’s ok. We’re here to help."
  }

  return (
    <FormWrap fontSize={3} mb={3} color="black" m="0 auto" display="flex" alignItems="center" flexDirection="column">
      <Box mb={3}>
        <p>
          {customText}
        </p>
        <p>
          Just by answering these questions, you’ve taken a big step towards prioritizing long-term health and wellbeing for you and your infant.
        </p>
        <p>
        After a few more questions, we’ll show you your Chelsea Method plan.
        </p>
      </Box>
      <Button buttonType="submit" onClick={onSubmit}>NEXT</Button>
    </FormWrap>
  ); 
}

export default CMvPhysTherapy;
