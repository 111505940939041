import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse, { numberOfBirths } from 'survey/v1/survey-response';

const BirthNumber = (props: { onSubmit: (id: keyof typeof numberOfBirths) => any, defaultResponse?: SurveyResponse }) => {

  const { onSubmit, defaultResponse } = props;

  let defaultSelection;
  if (defaultResponse?.currentPregnancy?.birthNumber !== undefined) {
    defaultSelection = [defaultResponse?.currentPregnancy?.birthNumber];
  }

  return <Box m="0 auto">
    <MultipleChoice
      question="What number birth will this be for you?"
      onChange={(selected) => onSubmit(selected[0] as (keyof typeof numberOfBirths))}
      choices={Object.entries(numberOfBirths).map(s => ({ id: s[0], text: s[1].textOrdinal }))}
      defaultSelection={defaultSelection}
    />
  </Box> 
}

export default BirthNumber;
