import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse, { confidenceLevel } from 'survey/v1/survey-response';

const StickToPlanConfidence = (props: { onSubmit: (id: keyof typeof confidenceLevel) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  let defaultSelection;
  if (defaultResponse?.confidenceLevel !== undefined) {
    defaultSelection = [defaultResponse?.confidenceLevel];
  }

  return <Box>
    <MultipleChoice
      question={
        <div>
          <p>Our postpartum recovery programs have a recommended schedule of 3 days a week, with classes ranging from 5 to 30 minutes.</p>
          <p>How confident are you that you’ll be able to stick to a plan like that?</p>
        </div>
      }
      onChange={(selected) => onSubmit(selected[0] as (keyof typeof confidenceLevel))}
      choices={Object.entries(confidenceLevel).map(s => ({ id: s[0], text: s[1].text }))}
      defaultSelection={defaultSelection}
    />
  </Box> 
}

export default StickToPlanConfidence;
