import './App.less';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Survey from './survey/v1/SurveyIndex';
import theme from './theme';
import { ThemeProvider } from 'styled-components';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Switch>
            <Route path="/survey">
              <Survey />
            </Route>
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
