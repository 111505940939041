import Box from "components/Box"
import moment from "moment";
import DateChoice from "survey/components/DateChoice";
import SurveyResponse from "./survey-response";

const DueDate = (props: { onSubmit: (date?: string) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  let defaultDate;
  if (defaultResponse?.currentPregnancy?.dueDate !== undefined) {
    defaultDate = defaultResponse?.currentPregnancy?.dueDate;
  }

  return (
    <Box>
      <DateChoice
        question={
          <div>
            <p>
              Congrats! When are you due?
            </p>
          </div>
        }
        onSubmit={onSubmit}
        defaultValue={defaultDate}
        disabled={(current) => {
          return current && current.isBefore(moment().subtract(3, "weeks"));
        }}
      />
    </Box>
  ); 
}

export default DueDate;