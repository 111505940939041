import { ReactNode, useState } from 'react';
import { Transition } from 'react-transition-group';

const duration = 350;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  transform: 'translateY(-32px)',
  opacity: 0,
}

const transitionStyles = {
  unmounted: { opacity: 0, transform: 'translateY(32px)' },
  entering: { opacity: 1, transform: 'translateY(0px)' },
  entered:  { opacity: 1, transform: 'translateY(0px)' },
  exiting:  { opacity: 0, transform: 'translateY(32px)' },
  exited:  { opacity: 0, transform: 'translateY(32px)' },
};

type FadeProps = {
  delay: number,
  children: ReactNode,
}

const FadeIn = (props: FadeProps) => {
  const [val, setVal] = useState<boolean>();

  setTimeout(() => setVal(true), props.delay);

  return (<Transition in={val} timeout={duration}>
    {state => (
      <div style={{
        ...defaultStyle,
        ...transitionStyles[state]
      }}>
        {props.children}
      </div>
    )}
  </Transition>)
};

export default FadeIn;