import Box from "components/Box"
import moment from "moment";
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse, {
  BirthTypeEnum,
  CMContentEnum,
  postpartumSymptoms,
} from "survey/v1/survey-response";
import { niceList, recoPostpartumProgram } from "./util";

const PPRecovery1 = (props: { onSubmit: (response: boolean) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse: res } = props;

  let content1 = '';
  let content2 = '';

  const ppConcerns = res?.currentPregnancy?.postpartumConcerns;
  const ppIssues = res?.postpartumSymptoms;
  const concerns: (keyof typeof postpartumSymptoms)[] = ppIssues || ppConcerns || [];

  if (concerns.length === 0) {
    content1 = "<p>Studies show that physical therapy exercises—like the ones we teach—help address a range of common birth-related issues.</p>";
  } else {
    content1 = `<p>Studies show that physical therapy exercises—like the ones we teach—help address issues like ${niceList(
      concerns.map((c) => postpartumSymptoms[c].text)
    )}.</p>`;
  }

  const recoProgram = res && recoPostpartumProgram(res);
  if (recoProgram) {
    content2 = Object.values(recoProgram)[0].reasonHtml;
  }

  console.log(content2);

  return <Box>
    <MultipleChoice
      question={<div><div dangerouslySetInnerHTML={{ __html: content1 }}/><div dangerouslySetInnerHTML={{ __html: content2 }}/></div>}
      onChange={(selected) => {
        const response = selected[0] === 'yes' ? true : false;
        onSubmit(response)
      }}
      choices={[
        { id: 'yes', text: `tell me more` },
        { id: 'no', text: 'not now' },
      ]}
    />
  </Box> 
}

export default PPRecovery1;
