import { Button } from "antd";
import Box from "components/Box";
import styled from "styled-components";
import { variant } from "styled-system";
import theme from "theme";

export default styled(Button)<{
  theme?: typeof theme;
  buttonType?: "filled" | "outline" | "submit";
  buttonHeight?: "default" | "short"
}>(
  {
    display: "block",
    height: "50px",
    border: "2px solid transparent",
    borderRadius: 0,
    fontWeight: 700,
    fontSize: `${theme.fontSizes[3]}px`,
    boxShadow: "none",
    color: theme.colors.primary,
    transition: "none",
    "&:hover": {
      backgroundColor: theme.colors.primary,
      color: "white",
      border: "2px solid transparent",
    },
  },
  variant({
    prop: "buttonType",
    variants: {
      filled: {
        width: "100%",
        backgroundColor: theme.colors.primary,
        borderColor: "transparent",
        color: "white",
        "&:hover": {
          backgroundColor: theme.colors.primary,
          color: "white",
          border: "2px solid transparent",
        },
        "&:focus": {
          backgroundColor: theme.colors.primary,
          color: "white",
          border: "2px solid transparent",
        },
      },
      outline: {
        width: "100%",
        background: "white",
        color: theme.colors.primary,
        borderColor: theme.colors.primary,
        "&:hover": {
          background: "white",
          color: theme.colors.primary,
          borderColor: theme.colors.primary,
        },
        "&:focus": {
          background: "white",
          color: theme.colors.primary,
          borderColor: theme.colors.primary,
        },
        "&:active": {
          background: theme.colors.primary,
          color: "white",
          borderColor: theme.colors.primary,
        },
      },
      submit: {
        backgroundColor: theme.colors.black90,
        border: "2px solid transparent",
        color: theme.colors.white,
        fontSize: theme.fontSizes[1],
        padding: "0 48px",
        textTransform: "uppercase",
        letterSpacing: "0.01rem",
        lineHeight: "1.2rem",
        borderRadius: '99999px',
        "&:hover": {
          border: "2px solid transparent",
          backgroundColor: theme.colors.black90,
          color: theme.colors.white,
        },
        "&:focus": {
          border: "2px solid transparent",
          backgroundColor: theme.colors.black90,
          color: theme.colors.white,
        },
        "&:active": {
          border: "2px solid transparent",
          backgroundColor: "black",
          color: theme.colors.white,
        },
        "&:disabled": {
          border: "2px solid transparent",
          backgroundColor: "rgba(0,0,0,0.1)",
        },
        "&:disabled:hover": {
          border: "2px solid transparent",
          backgroundColor: "rgba(0,0,0,0.1)",
        },
      },
    },
  }),
  variant({
    prop: "buttonHeight",
    variants: {
      default: {
        fontSize: `${theme.fontSizes[3]}px`,
        height: "50px",
      },
      short: {
        fontSize: `${theme.fontSizes[2]}px`,
        height: "40px",
      }
    }
  })
);