import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse, { NoneLittleLot } from "./survey-response";

const Coaching = (props: { onSubmit: (response: boolean) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  let defaultSelection;
  if (defaultResponse?.coachingHelpful !== undefined) {
    defaultSelection = defaultResponse?.coachingHelpful ? ['yes'] : ['no'];
  }

  let customContent;
  if (defaultResponse?.confidenceLevel === NoneLittleLot.Lot) {
    customContent = <>
      <p>We love a confident mama!</p>
      <p>But everyone can use a little help. All memberships come with a coach.</p>
    </>
  } else {
    customContent = <>
      <p>We get it. That’s why all memberships come with a coach.</p>
    </>
  }

  return <Box>
    <MultipleChoice
      question={
        <div>
          {customContent}
          <p>
            Our coaches send personalized, weekly check-ins via video to motivate you, congratulate you on your progress, and answer general questions.
          </p>
          <p>
            Do you think coaching will be helpful for you?
          </p>
        </div>
      }
      onChange={(selected) => {
        const response = selected[0] === 'yes' ? true : false;
        onSubmit(response)
      }}
      choices={[
        { id: 'yes', text: 'yes' },
        { id: 'no', text: 'no' },
      ]}
      defaultSelection={defaultSelection}
    />
  </Box> 
}

export default Coaching;