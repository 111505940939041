import Box from "components/Box";
import Button from "survey/components/Button";
import { FormWrap } from "survey/components/Form";
import SurveyResponse, { CMContentEnum, postpartumSymptoms } from 'survey/v1/survey-response';
import { niceList, recoPostpartumProgram } from "./util";

const PPRecovery2 = (props: { onSubmit: () => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse: res } = props;

  const recoProgram = res && recoPostpartumProgram(res);

  let content;
  let issues = res?.postpartumSymptoms || res?.currentPregnancy?.postpartumConcerns;
  let exampleText = '';

  if (issues && issues.length > 0) {
    exampleText = `—including ${niceList(issues.map(i => postpartumSymptoms[i].text))}—`;
  }

  if (recoProgram) {
    switch (Object.keys(recoProgram)[0]) {
      case CMContentEnum.VBR: {
        content = (
          <div>
            <p>
              Our streaming 12-week <b>Vaginal Birth Recovery program</b>—taught by our expert physical therapists—is designed to gradually guide you from a recent vaginal birth back to feeling safe and strong in your body again.
            </p>
            <p>
              The program addresses a wide-range of common postpartum dysfunction{exampleText}using exercise, movement, breathwork and postural training.
            </p>
            <p>
              We’ll work through our unique 3-phase method, with a recommended schedule of 3 days per week. Classes last between 5 and 30 minutes. They’re the perfect length for naptimes.
            </p>
          </div>
        );
        break;
      }
      case CMContentEnum.CSBR: {
        content = (
          <div>
            <p>
              Our streaming 12-week <b>C-Section Recovery program</b>—taught by our expert physical therapists—is designed to gradually guide you from a recent c-section back to feeling safe and strong in your body again.
            </p>
            <p>
              The program addresses a wide-range of common postpartum dysfunction{exampleText}using exercise, movement, breathwork and postural training.
            </p>
            <p>
              We’ll work through our unique 3-phase method, with a recommended schedule of 3 days per week. Classes last between 5 and 30 minutes. They’re the perfect length for naptimes.
            </p>
          </div>
        );
        break;
      }
      case CMContentEnum.RTE: {
        content = (
          <div>
            <p>
              Our streaming 12-week <b>Return to Exercise program</b>—taught by our expert physical therapists—is designed to guide you safely back to a regular fitness routine—whether you're 6 months or 6 years postpartum.
            </p>
            <p>
              The program addresses a wide-range of common postpartum dysfunction{exampleText}using exercise, movement, breathwork and postural training.
            </p>
            <p>
              We’ll work through our unique 3-phase method, with a recommended schedule of 3 days per week.
            </p>
          </div>
        );
        break;
      }
    }
  }

  return (
    <FormWrap>
      <Box fontSize={3} mb={5}>{content}</Box>
      <Box>
        <Button buttonType="submit" onClick={onSubmit} style={{ margin: '0 auto' }}>
          NEXT
        </Button>
      </Box>
    </FormWrap>
  ); 
}

export default PPRecovery2;
