import { DatePicker } from "antd"
import { ReactNode, useState } from "react";
import moment from 'moment';
import Box from "components/Box"
import { FormWrap } from 'survey/components/Form';
import Button from 'survey/components/Button';
import styled from "styled-components";

type DateChoiceProps = {
  onSubmit: (date?: string) => any;
  question: ReactNode;
  defaultValue?: string;
  disabled?: (current: moment.Moment) => boolean;
}

const SDatePicker = styled(DatePicker)`
  height: 50px;
  width: 260px;
  border-radius: 0;
  box-sizing: border-box;
  border-color: ${props => props.theme.colors.primary};
  border-width: 2px;

  &:hover {
    border-color: ${props => props.theme.colors.primary};
    border-right-width: 2px !important;
  }

  &.ant-picker-focused {
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.primary}20;
    border-right-width: 2px !important;
  }
  .ant-picker-input input {
    font-size: ${props => props.theme.fontSizes[3]}px;
  }
  .ant-picker-suffix svg {
    width: 1.3em;
    height: 1.3em;
  }
`;

const DateChoice = (props: DateChoiceProps) => {
  const { question, onSubmit, defaultValue, disabled } = props;
  const [date, setDate] = useState<string>();

  const onChange = (value: moment.Moment | null) => {
    if (value) {
      setDate(value.toString());
    } else {
      console.log('error getting date');
    }
  }

  return (
    <Box>
      <Box fontSize={3} mb={3}>
        {question}
      </Box>
      <FormWrap>
        <Box pl={2} pr={2}>
          <SDatePicker
            onChange={onChange}
            format="MMM Do YYYY"
            placeholder="select date..."
            defaultValue={moment(defaultValue)}
            disabledDate={disabled}
            inputReadOnly={true}
            style={{ width: '100%' }}
          />
        </Box>
        <Box
          width="260px"
          ml="auto"
          mr="auto"
          mt={5}
          display="flex"
          justifyContent="center"
        >
          <Button
            buttonType="submit"
            disabled={!defaultValue && !date}
            onClick={() => (date || defaultValue) && onSubmit(date)}
          >
            next
          </Button>
        </Box>
      </FormWrap>
    </Box>
  );
}

export default DateChoice;
