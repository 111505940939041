import Box from "components/Box";
import Button from "survey/components/Button";
import { FormWrap } from "survey/components/Form";
import SurveyResponse from "./survey-response";

const PRBPPrepInfo2 = (props: { onSubmit: () => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit } = props;

  return (
    <FormWrap fontSize={3} mb={3} color="black" m="0 auto" display="flex" alignItems="center" flexDirection="column">
      <Box mb={3}>
        <p>
          In this 2-hour streaming class, our expert physical therapists will show you how to identity birth-critical parts of your body that are tight.
        </p>
        <p>
          You’ll learn how to unlock your pelvis, loosen up muscles muscles and maximize your pushing power during delivery.
        </p>
      </Box>
      <Button buttonType="submit" onClick={onSubmit}>NEXT</Button>
    </FormWrap>
  ); 
}

export default PRBPPrepInfo2;