import Box from "components/Box";
import styled from "styled-components";

export const FormWrap = styled(Box)`
  max-width: 500px;
  padding: 8px;
  width: 100%;
  margin: 0 auto;
  font-size: ${p => p.theme.fontSizes[3]}px;
`;

export const ContinueWrap = styled(FormWrap)`
  margin: 32px auto;
`;