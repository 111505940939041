import Button from "survey/components/Button";
import Box from "components/Box"
import { useState } from "react";
import MultipleChoice from "survey/components/MultipleChoice";
import { ContinueWrap } from "survey/components/Form";
import SurveyResponse, { postpartumSymptoms } from "./survey-response";
import { useEffect } from "react";
import { useRef } from "react";

const PostpartumSymptoms = (props: { onSubmit: (keys: string[]) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;
  const [selected, setSelected] = useState<string[]>();
  const defaultSelection = useRef<string[] | undefined>();

  useEffect(() => {
    if (defaultResponse?.postpartumSymptoms !== undefined) {
      defaultSelection.current = defaultResponse?.postpartumSymptoms;
      setSelected(defaultResponse?.postpartumSymptoms);
    }
  }, [defaultResponse]);

  return (
    <Box>
      <MultipleChoice
        allowMultipleSelected
        question={
          <div>
            <p>Are you experiencing any of these common postpartum issues?</p>
          </div>}
        onChange={(selected) => { setSelected(selected); }}
        choices={Object.entries(postpartumSymptoms).map(s => ({ id: s[0], text: s[1].text }))}
        defaultSelection={defaultSelection.current}
      />
      <ContinueWrap m="0 auto" display="flex" justifyContent="center">
        <Button
          buttonType="submit"
          onClick={() => selected && onSubmit(selected)}
        >
          continue
        </Button>
      </ContinueWrap>
    </Box>
  );
}

export default PostpartumSymptoms;