import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse, { gestures } from 'survey/v1/survey-response';

const FavoriteGesture = (props: { onSubmit: (id: keyof typeof gestures) => any, defaultResponse?: SurveyResponse }) => {

  const { onSubmit, defaultResponse } = props;

  let defaultSelection;
  if (defaultResponse?.favoriteGesture !== undefined) {
    defaultSelection = [defaultResponse?.favoriteGesture];
  }

  return <Box m="0 auto">
    <MultipleChoice
      question="One last thing, just for fun. Which one?"
      onChange={(selected) => onSubmit(selected[0] as (keyof typeof gestures))}
      choices={Object.entries(gestures).map(s => ({ id: s[0], text: s[1].text }))}
      defaultSelection={defaultSelection}
    />
  </Box> 
}

export default FavoriteGesture;
