import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse, { thoughtBirthRecovery } from 'survey/v1/survey-response';

const BirthRecoveryThought1 = (props: { onSubmit: (id: keyof typeof thoughtBirthRecovery) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  let defaultSelection;
  if (defaultResponse?.physActivityLevel !== undefined) {
    defaultSelection = [defaultResponse?.physActivityLevel];
  }

  const content = <div>
    <p>Ok, let’s talk about postpartum.</p>
    <p>The American College of Obstetricians and Gynecologists recommends that pregnant women should have a “postpartum care plan.”</p>
    <p>How much have you thought about what happens after you give birth?</p>
  </div>

  return <Box>
    <MultipleChoice
      question={content}
      onChange={(selected) => onSubmit(selected[0] as (keyof typeof thoughtBirthRecovery))}
      choices={Object.entries(thoughtBirthRecovery).map(s => ({ id: s[0], text: s[1].text }))}
      defaultSelection={defaultSelection}
    />
  </Box> 
}

export default BirthRecoveryThought1;
