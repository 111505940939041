import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse, { activityLevel } from 'survey/v1/survey-response';

const BirthNumber = (props: { onSubmit: (id: keyof typeof activityLevel) => any, defaultResponse?: SurveyResponse }) => {
  const { onSubmit, defaultResponse } = props;

  let defaultSelection;
  if (defaultResponse?.physActivityLevel !== undefined) {
    defaultSelection = [defaultResponse?.physActivityLevel];
  }

  return <Box>
    <MultipleChoice
      question="Generally, how physically active are you?"
      onChange={(selected) => onSubmit(selected[0] as (keyof typeof activityLevel))}
      choices={Object.entries(activityLevel).map(s => ({ id: s[0], text: s[1].text }))}
      defaultSelection={defaultSelection}
    />
  </Box> 
}

export default BirthNumber;
