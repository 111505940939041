import Box from "components/Box"
import MultipleChoice from "survey/components/MultipleChoice";
import SurveyResponse, { numberOfBirths } from 'survey/v1/survey-response';

const NumberOfPastBirths = (props: { onSubmit: (id: keyof typeof numberOfBirths) => any, defaultResponse?: SurveyResponse }) => {

  const { onSubmit, defaultResponse } = props;

  let defaultSelection;
  if (defaultResponse?.lastBirth?.birthNumber !== undefined) {
    defaultSelection = [defaultResponse?.lastBirth?.birthNumber];
  }

  return <Box>
    <MultipleChoice
      question="How many births have you had?"
      onChange={(selected) => onSubmit(selected[0] as (keyof typeof numberOfBirths))}
      choices={Object.entries(numberOfBirths).map(s => ({ id: s[0], text: s[1].text }))}
      defaultSelection={defaultSelection}
    />
  </Box> 
}

export default NumberOfPastBirths;
